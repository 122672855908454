import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import GrilleSaisie from "./Grilles/Retrospective/GrilleSaisie";
import { rescueHtml } from './components/Tools';

const SaisieRetro = (props) =>
{
	const currentUser = localStorage.getItem("session_token");
    const [budget, setBudget] = useState([]);
    const [organisme, setOrganisme] = useState('');
    const [objet, setObjet] = useState([]);
    const [annees, setAnnees] = useState([]);
    const [aff, setAff] = useState(false)
    let fctinv = window.location.pathname.split('/')[3];
    let recdep = window.location.pathname.split('/')[4];
    let hypothese = 'R';

    const purple = '#6571FE'


    async function getData_brut(id_budget, liste_an)
    {
        const params = {
            'getGrilleSaisieRetro': true,
            'id_budget': id_budget,
            'fctinv': fctinv,
            'recdep': recdep,
            'hypothese': hypothese,
            'an_deb': liste_an[0],
            'an_fin': liste_an[liste_an.length-1]
        }
        console.log(params)

        await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
        .then(async (ret) => {
            console.log(ret.data)
            var tmp = []
            // if (ret.data && ret.data.length != 0)
            // {
                var i = 0
                await ret.data.forEach(async (element) => {
                    const infos = [element[0].matricule, element[0].compte, element[0].nom, element[0].indentation, element[0].calcul]
                    tmp[i] = [infos]
                    var j = 1
                    element[1].forEach(annee => {
                        tmp[i][j] = []
                        tmp[i][j][0] = annee[0]
                        tmp[i][j][1] = [annee[1][0], annee[1][1]]
                        tmp[i][j][2] = false
                        j++;
                    });
                    i++;
                });
                await setObjet(tmp)
            // }
        })
        .catch((err) => {
            console.log(err)
        })

    }


    useEffect(async () => {
        if (props.user === null) {
            await props.getUser()
        }
        else
        {
            if (currentUser) {
                await axios.post('https://app-slfinance.fr/api/connexion.php', qs.stringify({'session_token': currentUser}))
                .then(async (ret) => {
                    // si le session_token correspond bien a un utilisateur
                    if (ret.data.id_organisme && ret.data.nom_organisme)
                    {
                        // Recuperation du budget actuel
                        await axios.post('https://app-slfinance.fr/api/budgets.php', qs.stringify({'getBudgets': true, 'id': ret.data.id_budget}))
                        .then(async (res) => {
                            if (res.data.length !== 0) {
                                await setBudget(res.data[0])
                            }
                            else {
                                NotificationManager.warning("Budget inexistant", '', 700);
                                setTimeout(() => window.location = '/', 900);
                            }
                        }).catch((err) => {console.log(err)})
                        
                        // Recuperation de l'organisme actuel
                        await axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true,'id': ret.data.id_organisme}))
                        .then(async (res) => {
                            if (res.data[0]) {
                                await setOrganisme(rescueHtml(res.data[0].nom))
                            }
                        }).catch((err) => {console.log(err)})
                        
                        // Recuperation de la grille de saisie et des annees de retro
                        let retro_an_deb = ''
                        let retro_an_fin = ''
                        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+ret.data.id_budget)
                        .then((result) => {
                            result.data.forEach(element => {
                                if (element[2] === 'retro_an_deb') {
                                    retro_an_deb = parseInt(element[3])
                                }
                                if (element[2] === 'retro_an_fin') {
                                    retro_an_fin = parseInt(element[3])
                                }
                            });
                        }).catch((err) => {console.log(err)})
            
                        if (retro_an_deb === '' || retro_an_fin === '')
                        {
                            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
                            .then(async function (res) {
                                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                                    if (retro_an_deb === '') {
                                        retro_an_deb = parseInt(res.data.retro_an_deb)
                                    }
                                    if (retro_an_fin === '') {
                                        retro_an_fin = parseInt(res.data.retro_an_fin)
                                    }
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        }
                        var liste_an = []
                        var i = 0
                        while (retro_an_deb <= retro_an_fin)
                        {
                            liste_an[i] = parseInt(retro_an_deb)
                            retro_an_deb++;
                            i++;
                        }
                        await setAnnees(liste_an)
                        await getData_brut(ret.data.id_budget, liste_an)
                    }
                    else {
                        NotificationManager.warning("Organisme inexistant", '', 700);
                        setTimeout(() => window.location = '/', 900);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
    }, [props.user])

	return (
        <div>
            <NotificationContainer />
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <p>Retrospective</p>
                        <p style={{marginLeft: 5}}>\</p>
                        <p style={{marginLeft: 5}}>{fctinv.toLowerCase() === 'inv'?'Investissement':'Fonctionnement'}</p>
                        <p style={{marginLeft: 5}}>\</p>
                        {recdep.toLowerCase() === 'recette'?
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Recettes</p>
                        :null}
                        {recdep.toLowerCase() === 'depense'?
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Dépenses</p>
                        :null}
                    </div>

                    <p style={{flex: 1, textAlign: 'center', margin: 20}}>{budget.nom}</p>

                    <div style={{flex: 1}}></div>
                </div>
                {props.user && budget && annees.length != 0?
                <GrilleSaisie
                    fctinv={fctinv}
                    recdep={recdep}
                    hypothese={hypothese}
                    id_budget={budget.id}
                    objet={objet}
                    user={props.user}
                    setObjet={setObjet}
                    aff={aff}
                    setAff={setAff}
                    annees={annees}
                    nom_organisme={organisme}
                    nom_budget={budget[2]}
                />
                :null}
            </Access>
        </div>
	);
}

export default SaisieRetro;
