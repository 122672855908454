import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import LogoSLF from '../Img/logo_SLF.png';
import { rescueHtml, addSpace } from "../components/Tools";


const Page28FicheProjet = (props) =>
{
    const greyChapter = '#DBDBDB'
    const smoothGrey = '#f4f4f4';
    const blue = '#069BD3';

    const [incidences_financieres, setIncidences_financieres] = useState(null);
    const id_projet = props.projet[0];
    const num_projet = props.projet[1];
    const nom_projet = props.projet[2] !== null?rescueHtml(props.projet[2]):'Nouveau projet';
    const enjeux = props.projet[3] !== null?rescueHtml(props.projet[3]):'';
    const description_actions = props.projet[4] !== null?rescueHtml(props.projet[4]):'';
    const montant = props.projet[5] !== null?addSpace(props.projet[5]):'0,00';


    useEffect(async () => {
        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'get': true,
            'annees': props.yearsProsp,
            'id_projet': id_projet
        }))
        .then(async (res) => {
            
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 1) {
                        if (!parseFloat(elem[1])) {
                            elem[1] = 0;
                        }
                        else {
                            elem[1] = parseFloat(elem[1]);
                        }
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', Math.round(total[k])])
            });

            setIncidences_financieres(res.data)
        })
    }, [])

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={LogoSLF} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>Projet n°{num_projet}</p>
            </div>


            <div style={{flex: 1, marginTop: 25, height: '80%', textAlign: 'center'}}>

                {/* Container 1 */}
                <div className='shadow-md rounded-md' style={{margin: 25, fontSize: 12}}>

                    <div style={{flex: 1, display: 'flex', textAlign: 'center'}}>
                        <div style={{minWidth: 200, alignItems: 'flex-end', justifyContent: 'center', backgroundColor: smoothGrey, color: blue, borderTopLeftRadius: 5}}>
                            <p style={{margin: 5}}>Intitulé du projet</p>
                        </div>
                        <div style={{flex: 3, textAlign: 'left', borderLeft: '1px solid '+blue, justifyContent: 'center'}}>
                            <p style={{margin: 5}}>{nom_projet}</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'center', borderLeft: '1px solid '+blue, justifyContent: 'center', borderTopRightRadius: 5}}>
                            <p style={{margin: 5}}>Projet n°{num_projet}</p>
                        </div>
                    </div>

                    <div style={{flex: 2, display: 'flex', textAlign: 'center', borderTop: '1px solid '+blue}}>
                        <div style={{minWidth: 200, alignItems: 'flex-end', justifyContent: 'center', backgroundColor: smoothGrey, color: blue}}>
                            <p style={{margin: 5}}>Descriptif des enjeux</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'left', borderLeft: '1px solid '+blue, justifyContent: 'center'}}>
                            <p style={{margin: 5}}>{enjeux.length>330?enjeux.substring(0, 330)+' [...]':enjeux}</p>
                        </div>
                    </div>

                    <div style={{flex: 2, display: 'flex', textAlign: 'center', borderTop: '1px solid '+blue}}>
                        <div style={{minWidth: 200, alignItems: 'flex-end', justifyContent: 'center', backgroundColor: smoothGrey, color: blue}}>
                            <p style={{margin: 5}}>Description de l'action</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'left', borderLeft: '1px solid '+blue, justifyContent: 'center'}}>
                            <p style={{margin: 5}}>{description_actions.length>330?description_actions.substring(0, 330)+' [...]':description_actions}</p>
                        </div>
                    </div>

                    <div style={{flex: 1, display: 'flex', textAlign: 'center', borderTop: '1px solid '+blue}}>
                        <div style={{minWidth: 200, alignItems: 'flex-end', justifyContent: 'center', backgroundColor: smoothGrey, color: blue, justifyContent: 'center'}}>
                            <p style={{margin: 5}}>Montant du projet</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'left', borderLeft: '1px solid '+blue, justifyContent: 'center'}}>
                            <p style={{margin: 5}}>{montant} €</p>
                        </div>
                    </div>
                </div>


                {/* Container 2 */}
                <div className='shadow-md rounded-md' style={{margin: 25}}>

                    <div style={{flex: 1, display: 'flex', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                        <div style={{flex: 1, display: 'flex', color: 'white', textAlign: 'center'}}>
                            <div style={{width: 300, justifyContent: 'center'}}>
                                <p style={{fontSize: 10}}>Intitulés</p>
                            </div>

                            {props.yearsProsp.map((year, k) =>
                            k > 0?
                            <div key={k} style={{flex: 1, justifyContent: 'center'}}>
                                <p style={{fontSize: 10}}>{year}</p>
                            </div>
                            :null)}
                            <div style={{flex: 1, justifyContent: 'center', backgroundColor: '#2980b9', borderTopRightRadius: 5}}>
                                <p style={{fontSize: 12, fontWeight: 'bold'}}>Total</p>
                            </div>
                        </div>
                    </div>

                    {incidences_financieres !== null?
                    incidences_financieres.map((line, i) =>
                    <div key={i} style={
                        {flex: 1, display: 'flex', borderTop: '1px solid '+smoothGrey}}>
                        {line.map((val, j) =>
                        j === 0?
                        <div key={j} style={{width: 300}}>
                            <div key={j} style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{width: 300, flex: 1, justifyContent: 'center', backgroundColor: greyChapter, paddingLeft: 2, fontSize: 11}:{width: 300, flex: 1, justifyContent: 'center', textAlign: 'right', paddingRight: 5, fontSize: 11}}>
                                <p>{rescueHtml(val).replace('_', '\'')}</p>
                            </div>
                        </div>
                        :j > 2?
                        <div key={j} style={{flex: 1}}>
                            <div key={j} style={
                                val[0] !== 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                {flex: 1, justifyContent: 'center', backgroundColor: greyChapter, textAlign: 'right', paddingRight: 5, fontSize: 11}
                                :
                                val[0] === 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                {flex: 1, justifyContent: 'center', backgroundColor: '#BABABA', textAlign: 'right', paddingRight: 5, fontSize: 11, fontWeight: 'bold'}
                                :
                                val[0] === 'Total'?
                                {flex: 1, justifyContent: 'center', textAlign: 'right', paddingRight: 5, fontSize: 11, backgroundColor: greyChapter, fontWeight: 'bold'}
                                :
                                {flex: 1, justifyContent: 'center', textAlign: 'right', paddingRight: 5, fontSize: 11}}
                            >
                                <p style={{fontSize: 11}}>{val[1] !== '' && val[1] !== 0?addSpace(Math.round(val[1]), true):'.'}</p>
                            </div>
                        </div>
                        :null)}
                    </div>
                    ):null}
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page28FicheProjet;