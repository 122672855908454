import React, { useEffect, useState, useRef } from 'react';
import { Page, Image, Document, PDFViewer } from '@react-pdf/renderer';
import BounceLoader from "react-spinners/BounceLoader";
import { toPng } from 'html-to-image';
import Access from "../components/Access";
import Navbar from "../components/Navbar";
import { getyears } from "../components/Tools";
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as IconPdfWhite } from '../Img/icon-pdf-white.svg';
import Page1Accueil from "./Page1Accueil";
import Page2DepensesFct from "./Page2DepensesFct";
import Page3RecettesFct from "./Page3RecettesFct";
import Page4Investissement from './Page4Investissement';
import Page5DetteTreso from './Page5DetteTreso';


const GraphTableauDeBord = (props) =>
{
    const [aff, setAff] = useState(false)
    const [clicked, setClicked] = useState(false)
    const date = new Date()
    const day = date.getDate()<10?'0'+date.getDate():date.getDate();
    const month = (date.getMonth()+1)<10?'0'+(date.getMonth()+1):(date.getMonth()+1);
    const hour = date.getHours()<10?'0'+date.getHours():date.getHours();
    const minutes = date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes();
    const dateFR = 'Le  '+day+' / '+month+' / '+date.getFullYear()+' à '+hour+':'+minutes;
    const [affPDF, setAffPDF] = useState(false)
    const [year, setYear] = useState(date.getFullYear())
    const [years, setYears] = useState([])
    const [objet, setObjet] = useState([])
    
    const purple = '#6571FE'
    const green = '#59B44A'


    const elementRef1 = useRef(null);
    const [page1, setPage1] = useState(null)
    const elementRef2 = useRef(null);
    const [page2, setPage2] = useState(null)
    const elementRef3 = useRef(null);
    const [page3, setPage3] = useState(null)
    const elementRef4 = useRef(null);
    const [page4, setPage4] = useState(null)
    const elementRef5 = useRef(null);
    const [page5, setPage5] = useState(null)



    useEffect(async () => {
        if (!props.user) {
            props.getUser()
        }
        else {
            getyears(props.user.id_budget, 'R')
            .then(async (res) => {
                await res.push(res[res.length-1]+1);
                await res.push(res[res.length-1]+1);
                await res.sort((a, b) => b - a);
                await setYears(res)
                // await setYear(res[0])
                await getTableauBord(res)
                await setAff(!aff);
            })
        }
    }, [props.user])


    async function getTableauBord(annees)
    {
        const url = 'https://app-slfinance.fr/api/tableau_bord.php';
        const params = {
        	'get_grille_graph_tab_bord': true,
            'id_budget': props.user.id_budget,
            'years': annees
        };
        await axios.post(url, qs.stringify(params))
        .then(async (res) => {
            await setObjet(res.data)
        })
    }




    const htmlToImageConvert = async() => {
        await toPng(elementRef1.current, { cacheBust: false })
        .then((dataUrl) => {
            setPage1(dataUrl)
        })
        await toPng(elementRef2.current, { cacheBust: false })
        .then((dataUrl) => {
            setPage2(dataUrl)
        })
        await toPng(elementRef3.current, { cacheBust: false })
        .then((dataUrl) => {
            setPage3(dataUrl)
        })
        await toPng(elementRef4.current, { cacheBust: false })
        .then((dataUrl) => {
            setPage4(dataUrl)
        })
        await toPng(elementRef5.current, { cacheBust: false })
        .then((dataUrl) => {
            setPage5(dataUrl)
        })
        await setClicked(false);
        await setAff(!aff);
    };


	return (
        <Access needConnection={true} type_user={[0]}>
            {affPDF === false?
            <Navbar />
            :null}
            
            {years.length !== 0 && year !== undefined && affPDF === false?
            <div style={{display: 'flex'}}>
                
                {/* Selection de l'annee */}
                <div style={{margin: 10}}>
                    <select className='shadow-md' style={{padding: 5, margin: 15, textAlign: 'center', minWidth: 150}}
                    value={year}
                    onChange={async(e) => {
                        await setYear(e.target.value)
                        await setAff(!aff);
                    }}>
                        {years.map(annee =>
                        <option key={annee} value={annee}>{annee}</option>
                        )}
                    </select>
                </div>

                {/* <div style={{marginTop: 0, textAlign: 'center'}}>
                    <button
                    className='shadow-md rounded-md'
                    style={{backgroundColor: green, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}}
                    onClick={async() => {
                        if (props.user) {
                            await setClicked(true);
                            await htmlToImageConvert();
                            await setAffPDF(true);
                        }
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconPdfWhite width="18"/>
                            <p style={{margin: 5}}>Céer le PDF</p>
                        </div>
                    </button>
                </div> */}

                <div style={{flex: 1, marginTop: 25, textAlign: 'center'}}>
                    <p style={{color: purple, fontSize: 20}}>Prévisualisation du PDF</p>
                </div>

                <div style={{}}>
                    {affPDF === false?
                    clicked === false?
                    <button
                    className='shadow-md rounded-md'
                    style={{backgroundColor: green, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}}
                    onClick={async() => {
                        if (props.user) {
                            await setClicked(true);
                            await htmlToImageConvert();
                            await setAffPDF(true);
                        }
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconPdfWhite width="18"/>
                            <p style={{margin: 5}}>Céer le PDF</p>
                        </div>
                    </button>
                    :
                    <div style={{marginTop: 20, marginRight: 100}}>
                        <BounceLoader color={purple} />
                    </div>
                    :null}
                </div>
            </div>
            :null}


            {props.user?
            <div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            
            {affPDF?
            <PDFViewer width='100%' height={window.innerHeight}>
                <Document title={'Bilan'}>
                    <Page size="A4" orientation="landscape">
                        <Image src={page1} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    <Page size="A4" orientation="landscape">
                        <Image src={page2} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    <Page size="A4" orientation="landscape">
                        <Image src={page3} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    <Page size="A4" orientation="landscape">
                        <Image src={page4} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    <Page size="A4" orientation="landscape">
                        <Image src={page5} style={{width: '100%', height: '100%'}}/>
                    </Page>
                </Document>
            </PDFViewer>
            :
            <div style={{flex: 1}}>
                    {/* PARTIE REACT PRÉVISUALISATION */}
                    <div style={{flex: 1, margin: 25, display: 'flex'}}>
                        <div style={{flex: 0.1}}></div>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef1}>
                            <Page1Accueil user={props.user} date={dateFR} year={year}/>
                        </div>
                        <div style={{flex: 0.1}}></div>
                    </div>

                    <div style={{flex: 1, margin: 25, display: 'flex'}}>
                        <div style={{flex: 0.1}}></div>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef2}>
                            <Page2DepensesFct user={props.user} year={year} date={dateFR} objet={objet} titre={'Dépenses de fonctionnement'} index={2} nbPages={5} />
                        </div>
                        <div style={{flex: 0.1}}></div>
                    </div>

                    <div style={{flex: 1, margin: 25, display: 'flex'}}>
                        <div style={{flex: 0.1}}></div>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef3}>
                            <Page3RecettesFct user={props.user} year={year} date={dateFR} objet={objet} titre={'Recettes de fonctionnement'} index={3} nbPages={5} />
                        </div>
                        <div style={{flex: 0.1}}></div>
                    </div>

                    <div style={{flex: 1, margin: 25, display: 'flex'}}>
                        <div style={{flex: 0.1}}></div>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef4}>
                            <Page4Investissement user={props.user} year={year} date={dateFR} objet={objet} titre={'Investissement'} index={4} nbPages={5} />
                        </div>
                        <div style={{flex: 0.1}}></div>
                    </div>

                    <div style={{flex: 1, margin: 25, display: 'flex'}}>
                        <div style={{flex: 0.1}}></div>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef5}>
                            <Page5DetteTreso user={props.user} year={year} date={dateFR} objet={objet} titre={'Dette & trésorerie'} index={5} nbPages={5} />
                        </div>
                        <div style={{flex: 0.1}}></div>
                    </div>
                </div>}
            </div>
            :null}

        </Access>
	);
}

export default GraphTableauDeBord;
