import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanResultatCloture31_12(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(async () => {

        var filename = "Résultat de clôture au 31/12";

        await axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
        	'ResultatCloture31_12': true,
            'id_budget': props.user.id_budget,
            'nomen': props.user.nomen,
            'annees': props.annees
        }))
        .then(async (res) => {
            var state = {
                options: {
                    title: {
                        text: filename
                    },
                    chart: {
                        toolbar: {
                            show: props.download?true:false,
                            export: {
                                svg: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                                png: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                                csv: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                            }
                        },
                        type: 'bar'
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4
                        },
                    },
                    xaxis: {
                        categories: props.annees
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return addSpace(Math.round(value*100)/100, true) + " €";
                            }
                        },
                    },
                    dataLabels: {
                        formatter: (val) => {
                            return '';
                        }
                    },
                    fill: {
                        opacity: 1
                    }
                }
            }

            var tmp = [];
            if (props.affH1) {
                await tmp.push({
                    name: "Hypothèse 1",
                    data: res.data[0],
                    color: '#2ecc71',
                    type: 'column'
                })
            }
            if (props.affH2) {
                await tmp.push({
                    name: "Hypothèse 2",
                    data: res.data[1],
                    color: '#2c3e50',
                    type: 'column'
                })
            }
            if (props.affH3) {
                await tmp.push({
                    name: "Hypothèse 3",
                    data: res.data[2],
                    color: '#00b894',
                    type: 'column'
                })
            }
            state.series = tmp;

            await setOptions(state.options)
            await setSeries(state.series)
        })
    }, [props.aff])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:300}
                type="bar"
            />:null}
		</div>
	)
}

export default BilanResultatCloture31_12;
