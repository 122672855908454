import React, { useEffect, useState } from 'react';
import Navbar from "./components/Navbar";
import Access from "./components/Access";
import axios from 'axios';
import qs from 'qs';
import { escapeHtml, rescueHtml } from './components/Tools';
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Check } from './Img/icon-check.svg';

const CommentaireHypothese = (props) =>
{
    const [nom, setNom] = useState('')
    const [commentaire, setCommentaire] = useState('')
    const [hypothese, setHypothese] = useState(null)

    const purple = '#6571FE'
    const green = '#59B44A'


    // Récuperation de la liste des fiches projets
    useEffect(async () => {
        if (props.user === null) {
            await props.getUser()
        }
        else {
            setHypothese('h'+props.user.hypothese)
            await axios.post('https://app-slfinance.fr/api/hypothese.php', qs.stringify({
                'get': true,
                'id_budget': props.user.id_budget,
                'hypothese': 'h'+props.user.hypothese
            }))
            .then((res) => {
                if (res.data.length !== 0) {
                    setNom(rescueHtml(res.data[0][1]))
                    setCommentaire(rescueHtml(res.data[0][2]))
                }
            })
            // Recup' le nom et le commentaire de l'hypothese
        }
    }, [props.user])


    function validation(hyp)
    {
        if (nom !== null || commentaire !== null)
        {
            axios.post('https://app-slfinance.fr/api/hypothese.php', qs.stringify({
                'update': true,
                'id_budget': props.user.id_budget,
                'hypothese': hyp,
                'nom': escapeHtml(nom),
                'commentaire': escapeHtml(commentaire)
            }))
            .then((res) =>
            {
                if (res.data.status === 1) {
                    NotificationManager.success('Modification validée', '', 600);
                    setTimeout(() => window.location = window.location.href, 700);
                }
                else {
                    NotificationManager.warning(res.data.status_message, '', 3000);
                }
            })
        }
        else {
            NotificationManager.warning('Aucun champ rempli', '', 3000);
        }
    }

    
	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />
                {hypothese !== null?
                <div style={{display: 'flex'}}>
                    {props.user?
                    <p style={{flex: 1, textAlign: 'center', margin: 20}}>{props.user.nom_budget}</p>
                    :null}
                </div>
                :null}

                {props.user?
                <div style={{flex: 1, marginTop: 35, display: 'flex'}}>
                    <div style={{flex: 0.5}}></div>

                    <div style={{flex: 1}}>
                        <p>Nom de l'hypothèse {props.user.hypothese} :</p>
                        <div style={{}}>
                            <input
                            className='shadow-xl'
                            style={{flex: 0.4, padding: 5, borderRadius: 5, marginTop: 10, width: 500}}
                            onChange={async (e) => {
                                setNom(e.target.value)
                            }}
                            value={nom !== null?nom:''}
                            />
                        </div>
                    </div>

                    <div style={{flex: 0.5}}></div>
                </div>
                :null}

                <div style={{flex: 1, marginTop: 35, display: 'flex'}}>
                    <div style={{flex: 0.5}}></div>

                    <div style={{flex: 1}}>
                        <p>Commentaire :</p>
                        <div style={{flex: 1, display: 'flex'}}>
                            <textarea
                            className='shadow-xl'
                            style={{flex: 1, padding: 5, minHeight: 250, borderRadius: 5, marginTop: 10}}
                            onChange={async (e) => {
                                setCommentaire(e.target.value)
                            }}
                            value={commentaire !== null?commentaire:''}
                            />
                        </div>
                    </div>

                    <div style={{flex: 0.5}}></div>
                </div>

                {hypothese !== null?
                <div style={{flex: 1, marginTop: 35, display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <div style={{flex: 1, marginTop: 20}}>
                        <button
                        onClick={() => {
                            validation(hypothese);
                        }}>
                            <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <Check width="12" />
                                <p style={{display: 'flex', margin: 7}}>Valider</p>
                            </div>
                        </button>
                    </div>
                </div>
                :null}

            </Access>
        </div>
	);
}

export default CommentaireHypothese;
