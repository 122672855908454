import './App.css';
import React, { useEffect, useState } from 'react';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import {NotificationManager} from 'react-notifications';
import { addSpace, escapeHtml, rescueHtml } from "./components/Tools";
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Trash } from './Img/icon-trash.svg';
import { ReactComponent as Add } from './Img/icon-add.svg';
import { ReactComponent as Check } from './Img/icon-check.svg';
import Dialog from "./components/Dialog";

function Dette(props) {

    const [affExtinction, setAffExtinction] = useState(false)
    const [nouvelleBanque, setNouvelleBanque] = useState('')
    const [listeBanques, setListeBanques] = useState([])
    const [banqueToDelete, setBanqueToDelete] = useState(null)
    const [annees, setAnnees] = useState([]);
    const [objet, setObjet] = useState([]);
    const [extinction, setExtinction] = useState([]);
    const [total, setTotal] = useState([]);
    const [aff, setAff] = useState(false);
    const heightObject = window.innerHeight/1.9

    const purple = '#6571FE'
    const green = '#59B44A'
    const smoothGrey = '#f4f4f4'
    const blue = '#069BD3'

    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    async function callbackDialog(ret) {
        if (ret === true) {
            await axios.post('https://app-slfinance.fr/api/banques.php', qs.stringify({'delete': true, 'id_banque': banqueToDelete}))
            .then((res) => {
                if (res.data.status === 1) {
                    NotificationManager.success(res.data.status_message, '', 600);
                    setTimeout(() => window.location = window.location.href, 900);
                }
                else {
                    NotificationManager.warning(res.data.status_message, '', 700);
                }
            })
            await setBanqueToDelete(null)
        }
    }

    useEffect(async () => {
        if (!props.user) {
            props.getUser()
        }
        else
        {
            // Récupération des banques de ce budget
            const params = {
                'get': true,
                'id_budget': props.user.id_budget
            }
            await axios.post('https://app-slfinance.fr/api/banques.php', qs.stringify(params))
            .then(async (result) => {
                if (result.data.length !== 0) {
                    setListeBanques(result.data)
                }
            })
            // ************************************

            
            // Recuperation des annees de retro et prosp
            let retro_an_deb = ''
            let retro_an_fin = ''
            let fin_extinction_dette = ''
            
            await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
            .then((result) => {
                result.data.forEach(element => {
                    if (element[2] === 'retro_an_deb') {
                        retro_an_deb = parseInt(element[3])
                    }
                    if (element[2] === 'retro_an_fin') {
                        retro_an_fin = parseInt(element[3])
                    }
                });
            }).catch((err) => {console.log(err)})

            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (retro_an_deb === '' || retro_an_fin === '')
                {
                    if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                        if (retro_an_deb === '') {
                            retro_an_deb = parseInt(res.data.retro_an_deb)
                        }
                        if (retro_an_fin === '') {
                            retro_an_fin = parseInt(res.data.retro_an_fin)
                        }
                    }
                }
                if (fin_extinction_dette === '') {
                    fin_extinction_dette = parseInt(res.data.fin_extinction_dette)
                }
            })
            .catch((err) => {
                console.log(err)
            })

            var liste_an = []
            var i = 0
            while (retro_an_deb <= retro_an_fin)
            {
                liste_an[i] = parseInt(retro_an_deb)
                retro_an_deb++;
                i++;
            }
            var liste_an_prosp = []
            var i = 0
            var deb_extinction_dette = retro_an_fin + 1
            while (deb_extinction_dette <= fin_extinction_dette)
            {
                liste_an_prosp[i] = parseInt(deb_extinction_dette)
                deb_extinction_dette++;
                i++;
            }
            await setAnnees(liste_an)
            // ************************************


            // Recuperation datas extinction dette
            await axios.post('https://app-slfinance.fr/api/extinction_dette.php', qs.stringify({'get': true, 'id_budget': props.user.id_budget}))
            .then(async (res) => {
                var tmp = []
                liste_an_prosp.forEach((annee, i) => {
                    tmp[i] = [annee, 0, 0, 0, 0, 0]
                    res.data.forEach(line => {
                        if (parseInt(annee) === parseInt(line[2])) {
                            tmp[i] = [annee, parseFloat(line[3]), parseFloat(line[4]), parseFloat(line[5]), parseFloat(line[6]), parseFloat(line[7])]
                        }
                    });
                });
                
                // Recuperation datas encours dette
                await axios.post('https://app-slfinance.fr/api/encours_dette.php', qs.stringify({'getGrille': true, 'id_budget': props.user.id_budget, 'annees': liste_an}))
                .then(async (res) => {
                    await setObjet(res.data)
                    await tot(res.data, liste_an)
                    .then(async(result) => {
                        tmp[0][1] = result
                        // Calcul de l'extinction de la dette
                        await tmp.forEach((line, i) => {
                            if (tmp[i+1]) {
                                tmp[i+1][1] = (line[1] - line[2])
                            }
                            tmp[i][4] = line[2] + line[3]
                            if (line[1] !== 0 && line[1] !== '') {
                                tmp[i][5] = Math.round(((line[3]/line[1])*100)*100)/100
                            }
                        });        
                        await setExtinction(tmp)
                        await setAff(!aff)
                    })
                })
                // ************************************
            })
            // ************************************

        }
    }, [props.user])


    async function tot(tmp, liste_an)
    {
        var tot = []
        var totlastyear = 0
        tot[0] = ['Total']
        await liste_an.forEach((annee, i) => {
            tot[i+1] = [annee, 0]
        });
        await tmp.forEach(async (line) => {
            await line.forEach((elem, j) => {
                if (j > 0) {
                    tot[j][1] += Math.round(elem[1]*100)/100
                    tot[j][1] = Math.round(tot[j][1]*100)/100
                    totlastyear = tot[j][1]
                    totlastyear = Math.round(totlastyear*100)/100
                }
            });
        });
        await setTotal(tot)
        return totlastyear
    }


    async function validation()
    {
        await axios.post('https://app-slfinance.fr/api/encours_dette.php', qs.stringify({'update': true, 'objet': objet, 'total': total, 'id_budget': props.user.id_budget}))
        .then(async (res) => {
            if (res.data.status === 1) {
                await NotificationManager.success(res.data.status_message, '', 700);
            }
        })
        
        await axios.post('https://app-slfinance.fr/api/extinction_dette.php', qs.stringify({'update': true, 'objet': extinction, 'id_budget': props.user.id_budget}))
        .then(async (res) => {
            if (res.data.status === 1) {
                await NotificationManager.success(res.data.status_message, '', 700);
            }
        })
    }
    

    async function updateObjetLine(id_banque, an, montant)
    {
        var tmp = objet
        var i = 0
        while(i < tmp.length)
        {
            if (tmp[i][0][0] == id_banque)
            {
                var j = 1
                while(j < tmp[i].length)
                {
                    if (tmp[i][j] && tmp[i][j][0] === an)
                    {
                        tmp[i][j][1] = montant
                        tot(tmp, annees)
                        .then(async(result) => {
                            var ext = extinction
                            ext[0][1] = result
                            // Calcul de l'extinction de la dette
                            await ext.forEach((line, i) => {
                                if (ext[i+1]) {
                                    ext[i+1][1] = (line[1] - line[2])
                                }
                                ext[i][4] = line[2] + line[3]
                                if (line[1] !== 0 && line[1] !== '') {
                                    ext[i][5] = Math.round(((line[3]/line[1])*100)*100)/100
                                }
                            });        
                            await setExtinction(ext)
                            await setAff(!aff)
                        })
                        return
                    }
                    j++;
                }
            }
            i++;
        }
    }
    

    async function updateCapital(tmp, annee, montant)
    {
        await tmp.forEach((line, i) => {
            if (parseInt(line[0]) === parseInt(annee)) {
                tmp[i][2] = montant
            }
            if (tmp[i+1]) {
                tmp[i+1][1] =(tmp[i][1] - tmp[i][2])
            }
        });
        await updateAnnuiteTxApparent(tmp)
    }


    async function updateInterets(tmp, annee, montant)
    {
        await tmp.forEach((line, i) => {
            if (parseInt(line[0]) === parseInt(annee)) {
                tmp[i][3] = montant
            }
        });
        await updateAnnuiteTxApparent(tmp)
    }


    async function updateAnnuiteTxApparent(tmp)
    {
        await tmp.forEach((line, i) => {
            var annuite = (Math.round((line[2])*100)/100) + (Math.round((line[3])*100)/100)
            tmp[i][4] = (Math.round((annuite)*100)/100)
            if (line[1] !== 0 && line[1] !== '') {
                tmp[i][5] = ((line[3]/line[1])*100)
            }
        });
        await setExtinction(tmp)
        await setAff(!aff)
    }


    return (
        <div className='bgcolor'>

            <Navbar user={props.user} />

            <Access needConnection={true} type_user={[0]}>

                <Dialog
                    setModalVisible={setDialogModalVisible}
                    modalVisible={dialogModalVisible}
                    callbackDialog={callbackDialog}
                    message={"Confirmez la suppression :"}
                />

                <div className='bgcolor'>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1, margin: 20, display: 'flex'}}>
                            <div style={{flex: 1, display: 'flex'}}>
                                <p>Retrospective</p>
                                <p style={{marginLeft: 5}}>\</p>
                                <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Dette</p>
                            </div>
                            <div style={{flex: 1}}>
                                {props.user?
                                <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                                :null}
                            </div>
                            <div style={{flex: 1}}></div>
                        </div>
                    </div>

                    <div style={{flex: 1, display: 'flex', marginLeft: 50, marginRight: 50, marginTop: 20}}>
                        <button
                            className='shadow-md p-2 rounded-md'
                            style={!affExtinction?{flex: 1, backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{flex: 1, color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                            onClick={() => {
                                setAffExtinction(false)
                            }}
                        >
                            Répartition de l'encours de dette par prêteur
                        </button>
                        <button
                            className='shadow-md p-2 rounded-md'
                            style={affExtinction?{flex: 1, backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{flex: 1, color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                            onClick={() => {
                                setAffExtinction(true)
                            }}
                        >
                            Extinction de la dette (en euros)
                        </button>
                    </div>

                    {!affExtinction?
                    //******************************** Grille Répartition de l'encours de dette par prêteur ********************************
                    <div style={{marginRight: 50, marginLeft: 50, marginTop: 15}}>

                        <div style={{display: 'flex'}}>
                            {/* <p>Ajouter une Banque :</p> */}
                            <input
                                className='shadow-md rounded-md'
                                style={{width: 400, paddingLeft: 5, marginLeft: 50, border: '0.1px solid '+purple}}
                                onChange={(e) => {
                                    setNouvelleBanque(e.target.value)
                                }}
                                placeholder="Nouvelle Banque"
                            />
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', marginLeft: 10, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                                onClick={() => {
                                    if (nouvelleBanque !== '') {
                                        const params = {
                                            'add': true,
                                            'nom': escapeHtml(nouvelleBanque),
                                            'id_budget': props.user.id_budget,
                                            'rang': listeBanques.length + 1
                                        }
                                        axios.post('https://app-slfinance.fr/api/banques.php', qs.stringify(params))
                                        .then(async (result) => {
                                            if (result.data.status === 1) {
                                                await NotificationManager.success("Nouvelle banque ajoutée", '', 700);
                                                await validation()
                                                await setTimeout(() => window.location = window.location.href, 900);
                                            }
                                        })
                                    }
                                }}
                            >
                                <Add width="12"/>
                                <p style={{marginLeft: 5}}>Ajouter</p>
                            </button>

                            <div style={{flex: 1}}></div>

                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', marginLeft: 10, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                                onClick={async() => {
                                    await validation()
                                    await setTimeout(() => window.location = window.location.href, 900);
                                }}
                            >
                                <Check width="12"/>
                                <p style={{marginLeft: 5}}>Valider</p>
                            </button>
                        </div>


                        {/* Entete */}
                        <div style={{display: 'flex', marginTop: 50}}>
                            <div style={{minWidth: 50}}></div>
                            <div style={{display: 'flex', borderBottom: '1px solid '+blue, flex: 1, backgroundColor: blue}}>
                                <div style={{flex: 1, textAlign: 'center', color: 'white', fontSize: 18, minWidth: 250}}>
                                    <p>Liste des prêteurs</p>
                                </div>
                                {annees.map((annee, i) =>
                                <div key={i} style={{flex: 1, textAlign: 'center', color: 'white', fontSize: 18, minWidth: 100, borderLeft: '1px solid white'}}>
                                    <p>31/12/{annee}</p>
                                </div>
                                )}
                            </div>
                        </div>
                        
                        {/* Grille */}
                        {objet.map((banque, i) =>
                        <div key={i} style={{display: 'flex', minWidth: 50}}>
                            <button
                            onClick={async() => {
                                await setBanqueToDelete(banque[0][0])
                                await setDialogModalVisible(true);
                            }}>
                                <Trash width="50" />
                            </button>

                            <div style={{borderBottom: '1px solid '+blue, borderLeft: '1px solid '+blue, borderRight: '1px solid '+blue, display: 'flex', flex: 1}}>
                                <div style={{flex: 1, display: 'flex', minWidth: 250}}>
                                        {/* INPUT NOMS */}
                                        <input
                                        style={{flex: 1, textAlign: 'left', paddingLeft: 5}}
                                        onBlur={async (e) => {
                                            let val = escapeHtml(e.target.value)
                                            if (val !== '') {
                                                await axios.post('https://app-slfinance.fr/api/banques.php', qs.stringify({'updateName': true, 'newName': val, 'id_banque': banque[0][0]}))
                                                .then(async(res) => {
                                                    if (res.data.status === 1) {
                                                        await NotificationManager.success("Modification du nom validée", '', 600);
                                                        await validation()
                                                        await setTimeout(() => window.location = window.location.href, 900);
                                                    }
                                                    else {
                                                        NotificationManager.warning(res.data.status_message, '', 700);
                                                    }
                                                })
                                            }
                                        }}
                                        placeholder={rescueHtml(banque[0][1])}
                                        />
                                </div>

                                {banque.map((annee, j) =>
                                    j > 0?
                                    <div key={j} style={{flex: 1, display: 'flex', borderLeft: '1px solid '+blue, minWidth: 100}}>
                                        {/* INPUT MONTANTS */}
                                        <input
                                        type="float"
                                        style={{flex: 1, width: 2, textAlign: 'right', padding: 5}}
                                        onBlur={async (e) => {
                                            e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                            if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                                e.target.value = (Math.round(parseFloat(e.target.value)*100)/100)
                                                await updateObjetLine(banque[0][0], annee[0], parseFloat(e.target.value))
                                                await setAff(!aff)
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={addSpace(Math.round(annee[1] * 100) / 100)}
                                        />
                                    </div>
                                    :null
                                )}
                            </div>
                        </div>
                        )}

                        {/* Ligne Total */}
                        <div style={{display: 'flex'}}>
                            <div style={{minWidth: 50}}></div>
                            <div style={{borderBottom: '1px solid '+blue, borderLeft: '1px solid '+blue, borderRight: '1px solid '+blue, display: 'flex', flex: 1, backgroundColor: 'white'}}>
                                <div style={{flex: 1, display: 'flex', minWidth: 250}}>
                                    <p style={{marginLeft: 5, fontSize: 18}}>Total</p>
                                </div>

                                {total.map((annee, j) =>
                                    j > 0?
                                    <div key={j} style={{flex: 1, borderLeft: '1px solid '+blue, minWidth: 100}}>
                                        <p style={{textAlign: 'right', paddingRight: 5}}>{addSpace(annee[1])}</p>
                                    </div>
                                    :null
                                )}
                            </div>
                        </div>

                    </div>
                    // ******************************************************************************************************
                    :
                    //******************************** Grille Extinction de la dette ********************************
                    <div style={{marginRight: 50, marginLeft: 50, marginTop: 15}}>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}></div>
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', marginLeft: 10, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                                onClick={async() => {
                                    await validation()
                                    await setTimeout(() => window.location = window.location.href, 900);
                                }}
                            >
                                <Check width="12"/>
                                <p style={{marginLeft: 5}}>Valider</p>
                            </button>
                        </div>

                        <div className='shadow-xl'>
                            
                            <div style={{marginTop: 15, display: 'flex', textAlign: 'center', backgroundColor: blue, color: 'white', overflowY: 'scroll'}}>
                                <div style={{flex: 0.4, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <p style={{margin: 5}}>Année</p>
                                </div>
                                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                    <p style={{margin: 5}}>Capital restant dû au 01/01</p>
                                </div>
                                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                    <p style={{margin: 5}}>Remboursement en capital</p>
                                </div>
                                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                    <p style={{margin: 5}}>Intérêts</p>
                                </div>
                                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                    <p style={{margin: 5}}>Annuité</p>
                                </div>
                                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                    <p style={{margin: 5}}>Taux apparent</p>
                                </div>
                            </div>

                            <div style={{flex: 1, maxHeight: heightObject, overflowY: 'scroll'}}>
                                {extinction.map((line, i) =>
                                <div key={i} style={{display: 'flex', borderTop: '1px solid '+blue}}>

                                    <div style={{flex: 0.4, textAlign: 'center', backgroundColor: smoothGrey}}>
                                        <p style={{margin: 5}}>{line[0]}</p>
                                    </div>
                                    <div style={{flex: 1, textAlign: 'center', borderLeft: '1px solid '+blue, textAlign: 'right', backgroundColor: smoothGrey}}>
                                        <p style={{margin: 5}}>{line[1] !== 0?addSpace(Math.round(line[1]*100)/100):null}</p>
                                    </div>

                                    <div style={{flex: 1, textAlign: 'center', borderLeft: '1px solid '+blue, display: 'flex'}}>
                                        <input
                                        type="float"
                                        style={{flex: 1, textAlign: 'right', padding: 5}}
                                        onBlur={async (e) => {
                                            if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                                var montant = (Math.round(parseFloat(e.target.value)*100)/100)
                                                await updateCapital(extinction, line[0], montant)
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={line[2] !== 0?addSpace(Math.round(line[2]*100)/100):null}
                                        />
                                    </div>
                                    <div style={{flex: 1, textAlign: 'center', borderLeft: '1px solid '+blue, display: 'flex'}}>
                                        <input
                                        type="float"
                                        style={{flex: 1, textAlign: 'right', padding: 5}}
                                        onBlur={(e) => {
                                            if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                                var montant = (Math.round(parseFloat(e.target.value)*100)/100)
                                                updateInterets(extinction, line[0], montant)
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={line[3] !== 0?addSpace(Math.round(line[3]*100)/100):null}
                                        />
                                    </div>

                                    <div style={{flex: 1, textAlign: 'center', borderLeft: '1px solid '+blue, textAlign: 'right', backgroundColor: smoothGrey}}>
                                        <p style={{margin: 5}}>{line[4] !== 0?addSpace(Math.round(line[4]*100)/100):null}</p>
                                    </div>
                                    <div style={{flex: 1, textAlign: 'center', borderLeft: '1px solid '+blue, textAlign: 'right', backgroundColor: smoothGrey}}>
                                        <p style={{margin: 5}}>{line[5] !== 0?addSpace(Math.round(line[5]*100)/100)+' %':null}</p>
                                    </div>
                                    
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                    // ******************************************************************************************************
                    }
                </div>

            </Access>
        </div>
    );
}

export default Dette;