import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanHypEvolRecDepGestion(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(async () => {

        var filename = "Dynamique recettes et dépenses "+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'de gestion');

        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: props.download?true:false,
                    export: {
                        svg: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        png: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        csv: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                    },
                },
                type: 'line',
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
                text: filename
            },
            xaxis: {
                categories: props.annees
            },
            yaxis: {
                labels: {
                  formatter: function (value) {
                    return addSpace(Math.round(value*100)/100, true);
                  }
                },
            },
        }
        await setOptions(option_tmp)

        await axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
        	'HypEvolRecDepGestion': true,
            'id_budget': props.user.id_budget,
            'annees': props.annees,
        }))
        .then(async (res) => {
            var tmp = [];
            if (props.affH1) {
                await tmp.push({
                    name: "Recettes de gestion H1",
                    data: res.data[0],
                    color: '#2980b9',
                },{
                    name: "Dépenses de gestion H1",
                    data: res.data[1],
                    color: '#e74c3c',
                })
            }
            if (props.affH2) {
                await tmp.push({
                    name: "Recettes de gestion H2",
                    data: res.data[2],
                    color: '#8e44ad',
                }, {
                    name: "Dépenses de gestion H2",
                    data: res.data[3],
                    color: '#2c3e50',
                })
            }
            if (props.affH3) {
                await tmp.push({
                    name: "Recettes de gestion H3",
                    data: res.data[4],
                    color: '#16a085',
                },{
                    name: "Dépenses de gestion H3",
                    data: res.data[5],
                    color: '#f1c40f',
                })
            }
            await setSeries(tmp);
        })
    }, [props.aff])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:300}
                type="line"
            />:null}
		</div>
	)
}

export default BilanHypEvolRecDepGestion;
