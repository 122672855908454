import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanRepartRecettes(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(async () => {

        var filename = "Répartition des recettes " + (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'de gestion');

        var ComEpci = null;
        if (props.user.id_organisme_type === '1') {
            ComEpci = 'Com';
        }
        else if (props.user.id_organisme_type === '2') {
            ComEpci = 'Epci';
        }
        else if (props.user.id_organisme_type === '3') {
            ComEpci = 'Com';
        }

        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: props.download?true:false,
                    export: {
                        svg: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        png: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        csv: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                    },
                },
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%',
            },
            title: {
                text: filename
            },
            xaxis: {
                categories: props.annees
            },
            yaxis: {
                labels: {
                  formatter: function (value) {
                    return addSpace(Math.round(value*100)/100, true) + "%";
                  }
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4
                },
            },
            fill: {
              opacity: 1
            }
        }
        await setOptions(option_tmp)
        
        await axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
            'recettesGestion': true,
            'ComEpci': ComEpci,
            'id_budget': props.user.id_budget,
            'annees': props.annees,
            'hypothese': 'R'
        }))
        .then(async (res) => {
            await setSeries([{
                name: "Produits des services, domaine & ventes div",
                data: res.data[0],
                color: '#9b59b6',
                type: 'column',
            },{
                name: "Impôts et taxes",
                data: res.data[1],
                color: '#3498db',
                type: 'column'
            },{
                name: "Dotations et participations",
                data: res.data[2],
                color: '#e74c3c',
                type: 'column'
            },{
                name: "Autres recettes de fonctionnement",
                data: res.data[3],
                color: '#27ae60',
                type: 'column'
            }])
        })
    }, [])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:400}
                type="bar"
            />:null}
		</div>
	)
}

export default BilanRepartRecettes;
