import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { useForm } from "react-hook-form";
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';

const FeedRetroYears = (props) =>
{
    const [an_deb, setAn_deb] = useState('');
    const [an_fin, setAn_fin] = useState('');
    const [an_prosp_fin, setAn_prosp_fin] = useState('');
    const green = '#59B44A'

    useEffect(async () => {
        if (props.budget.id != undefined)
        {
            await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.budget.id)
            .then(async(result) => {
                var tmp_an_deb = ''
                var tmp_an_fin = ''
                var tmp_an_prosp_fin = ''
                await result.data.forEach(budget => {
                    if (budget[2] === 'retro_an_deb') {
                        tmp_an_deb = budget[3]
                        setAn_deb(tmp_an_deb)
                    }
                    if (budget[2] === 'retro_an_fin') {
                        tmp_an_fin = budget[3]
                        setAn_fin(tmp_an_fin)
                    }
                    if (budget[2] === 'prosp_an_fin') {
                        tmp_an_prosp_fin = budget[3]
                        setAn_prosp_fin(tmp_an_prosp_fin)
                    }
                });

                // S'il n'y a pas d'années enregistré on prend celles de base
                if (tmp_an_deb === '') {
                    await axios.post("https://app-slfinance.fr/api/application.php", qs.stringify({'get': true, 'parametre': 'retro_an_deb'}))
                    .then(function (ret) {
                        setAn_deb(ret.data.retro_an_deb)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
                if (tmp_an_fin === '') {
                    await axios.post("https://app-slfinance.fr/api/application.php", qs.stringify({'get': true, 'parametre': 'retro_an_fin'}))
                    .then(function (ret) {
                        setAn_fin(ret.data.retro_an_fin)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
                if (tmp_an_prosp_fin === '') {
                    await axios.post("https://app-slfinance.fr/api/application.php", qs.stringify({'get': true, 'parametre': 'prosp_an_fin'}))
                    .then(function (ret) {
                        setAn_prosp_fin(ret.data.prosp_an_fin)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }, [props.budget])

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    const onSubmit = async data => {
        var retro_deb = an_deb
        if (data.deb != '') {
            retro_deb = data.deb
        }
        var retro_fin = an_fin
        if (data.fin != '') {
            retro_fin = data.fin
        }
        var prosp_fin = an_prosp_fin
        if (data.prosp_fin != '') {
            prosp_fin = data.prosp_fin
        }

        if (retro_deb === '' || retro_fin === '' || prosp_fin === '') {
            NotificationManager.warning("Veuillez renseigner les champs", '', 3000);
        }
        else
        {
            if (retro_fin < retro_deb) {
                NotificationManager.warning("L'année de début ne doit pas être supérieure à l'année de fin", '', 3000);
            }
            if (prosp_fin <= retro_fin) {
                NotificationManager.warning("L'année de fin de prospective ne doit pas être inferieure ou égale à l'année de fin de retrospective", '', 5000);
            }
            if (retro_fin >= retro_deb && prosp_fin > retro_fin)
            {
                await axios.post('https://app-slfinance.fr/api/config_budget.php', qs.stringify({'set_config_budget': true, 'id_budget': props.budget.id, 'parametre': 'retro_an_deb', 'valeur': retro_deb}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 2000);
                    }
                    else {
                        NotificationManager.warning(res.data['status_message'], '', 2000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                await axios.post('https://app-slfinance.fr/api/config_budget.php', qs.stringify({'set_config_budget': true, 'id_budget': props.budget.id, 'parametre': 'retro_an_fin', 'valeur': retro_fin}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 2000);
                    }
                    else {
                        NotificationManager.warning(res.data['status_message'], '', 2000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                await axios.post('https://app-slfinance.fr/api/config_budget.php', qs.stringify({'set_config_budget': true, 'id_budget': props.budget.id, 'parametre': 'prosp_an_deb', 'valeur': (parseInt(retro_fin)+1).toString()}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 2000);
                    }
                    else {
                        NotificationManager.warning(res.data['status_message'], '', 2000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                await axios.post('https://app-slfinance.fr/api/config_budget.php', qs.stringify({'set_config_budget': true, 'id_budget': props.budget.id, 'parametre': 'prosp_an_fin', 'valeur': prosp_fin}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 2000);
                    }
                    else {
                        NotificationManager.warning(res.data['status_message'], '', 2000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                setTimeout(() => window.location = window.location.href, 900);
            }
        }
    };

	return (
        <div className='shadow-md' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                    <p>Modifier les années de rétrospective et prospective:</p>
                <div style={{display: 'flex', textAlign: 'left', padding: 10, marginTop: 20}}>
                    <div style={{flex: 1, textAlign: 'center'}}>
                        <p>Retrospective:</p>
                        <div style={{margin: 20}}>
                            <input style={{width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7}} placeholder={an_deb?an_deb:'Année de début'} {...register("deb")} />
                        </div>
                        <div style={{margin: 20}}>
                            <input style={{width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7}} placeholder={an_fin?an_fin:'Année de fin'} {...register("fin")} />
                        </div>
                    </div>
                    <div style={{flex: 1, textAlign: 'center', borderLeft: '1px solid black'}}>
                        <p>Prospective:</p>
                        <div style={{margin: 20}}>
                            <input style={{width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7}} placeholder={an_prosp_fin?an_prosp_fin:'Année de fin'} {...register("prosp_fin")} />
                        </div>
                    </div>
                </div>

                <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                    <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                        <Check width="12" />
                        <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Valider" />
                    </div>
                </div>
            </form>
        </div>
	);
}

export default FeedRetroYears;
