import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanDepensesPersonnel(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(async () => {

        var filename = "Dynamique des dépenses de personnel";

        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: props.download?true:false,
                    export: {
                        svg: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        png: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        csv: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                    },
                },
                type: 'line',
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
                text: filename
            },
            xaxis: {
                categories: props.annees
            },
            yaxis: {
                labels: {
                  formatter: function (value) {
                    return addSpace(Math.round(value*100)/100, true);
                  }
                },
            },
        }
        await setOptions(option_tmp)

        await axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
        	'DepensesPersonnel': true,
            'id_budget': props.user.id_budget,
            'annees': props.annees,
        }))
        .then(async (res) => {
            var tmp = [];
            if (props.affH1) {
                await tmp.push({
                    name: "Charges de personnel H1",
                    data: res.data[0],
                    color: '#c0392b',
                })
            }
            if (props.affH2) {
                await tmp.push({
                    name: "Charges de personnel H2",
                    data: res.data[1],
                    color: '#8e44ad',
                })
            }
            if (props.affH3) {
                await tmp.push({
                    name: "Charges de personnel H3",
                    data: res.data[2],
                    color: '#f1c40f',
                })
            }
            await setSeries(tmp);
        })
    }, [props.aff])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:300}
                type="line"
            />:null}
		</div>
	)
}

export default BilanDepensesPersonnel;
