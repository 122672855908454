import React, { useEffect, useState } from 'react';
import Access from "../../../components/Access";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { addSpace } from '../../../components/Tools';
import axios from 'axios';
import BounceLoader from "react-spinners/BounceLoader";
import qs from 'qs';
import { ReactComponent as Check } from '../../../Img/icon-check.svg';
import { ReactComponent as OpenEye } from '../../../Img/icon-open-eye.svg';
import { ReactComponent as CloseEye } from '../../../Img/icon-close-eye.svg';


function GrilleSaisie(props)
{
    const [validButton, setValidButton] = useState(false);
    const [displayAll, setDisplayAll] = useState(false);
    const greyChapter = '#DBDBDB'
    const purple = '#6571FE'
    const blue = '#069BD3'
    const blueTx = '#DEF3FF'
    const green = '#59B44A'

    async function calcul(obj)
    {
        await obj.forEach(async (line) => {
            if (line[0][2] !== '1' && line[1][1][0] !== 0) {
                await updateObject(line[0][0], line[1][0], line[1][1][1], 'taux')
            }
        });
        return obj
    }
    
    useEffect(async () => {
        // console.log(props)

        if (props.objet.length != 0) {
            await calcul(props.objet)
            // .then(async(tmp) => {
            //     await updateProduits(tmp)
            //     await validation()
            //     .then(() => {
            //         NotificationManager.success("Grille sauvegardée", '', 700);
            //     })
            // })
        }
    }, [props.objet])


    function emptyLine(line)
    {
        var ret = true
        line.forEach((element, i) => {
            if (i > 0) {
                if (element[1][0] !== '' && element[1][0] !== '0' && element[1][0] !== 0) {
                    ret = false
                }
            }
        });
        return ret;
    }



    async function sumProduit(objet)
    {
        var j = 0
        var k = 0
        // Mise a zero de la ligne REIPRD
        await objet.forEach(async(line, i) => {
            j = 0
            await line.forEach(() => {
                if (j > 0) {
                    if (objet[i][0][0] === 'REIPRD') {
                        objet[i][j][1][0] = 0
                        k = i
                    }
                }
                j++;
            });
        });

        await objet.forEach(async(line, i) => {
            j = 0
            await line.forEach(() => {
                if (j > 0) {
                    if (objet[i][0][0] !== 'REIPRD' && objet[i][0][0].includes('PRD')) {
                        objet[k][j][1][0] = objet[k][j][1][0] + parseFloat(objet[i][j][1][0])
                        objet[k][j][1][0] = Math.round(objet[k][j][1][0] * 100) / 100
                    }
                }
                j++;
            });
        });
        
        await objet.forEach(async(line, i) => {
            j = 0
            await line.forEach(() => {
                if (j > 1) {
                    if (objet[i][0][0] !== 'REIPRD' && objet[i][0][0].includes('PRD')) {
                        objet[i][j-1][1][1] = (objet[i][j][1][0] - objet[i][j-1][1][0]) / objet[i][j-1][1][0] * 100;
                    }
                }
                j++;
            });
        });

        await props.setObjet(objet)
        await props.setAff(!props.aff)
    }


    async function updateProduits(objet)
    {
        await objet.forEach(async(line, i) => {
            /* line[0][3] correspond à la colonne calcul */
            if (line[0][3] && line[0][3] !== '') {
                var matriculeBase = line[0][3].split(';')[0]
                var matriculeTaux = line[0][3].split(';')[1]
                
                await line.forEach(async(elem, j) => {
                    if (j > 0) {
                        var annee = elem[0]
                        var base = 0
                        var taux = 0
                        /* Récupération des bases et des taux */
                        await objet.forEach(async(tmp_line) => {
                            if (tmp_line[0][0] === matriculeBase || tmp_line[0][0] === matriculeTaux) {
                                await tmp_line.forEach((year) => {
                                    if (year[0] === annee) {
                                        if (tmp_line[0][0] === matriculeBase) {
                                            base = parseFloat(year[1][0])
                                        }
                                        else if (tmp_line[0][0] === matriculeTaux) {
                                            taux = (parseFloat(year[1][0])/100)
                                        }
                                    }
                                });
                            }
                        });
                        objet[i][j][1][0] = Math.round(base*taux)
                        // objet[i][j][1][1] = (objet[i][j+1][1][0] - objet[i][j][1][0]) / objet[i][j][1][0] * 100
                    }
                });
            }
        });
        await sumProduit(objet)
        // await props.setAff(!props.aff)
    }


    async function updateObject(matricule, annee, value, taux = '')
    {
        var tmp = props.objet
        var firstpassage = true
        await tmp.forEach((line, i) => {
            if (line[0][0] === matricule)
            {
                line.forEach(async(col, j) => {
                    if ((col[0] >= annee && taux === 'taux') || (col[0] === annee && taux === '')) {
                        if (value === '') {
                            value = 0
                        }
                        if (taux === '') {
                            const va = parseFloat(value)
                            var vd = parseFloat(tmp[i][j-1][1][0])
                            if (tmp[i][j-1][1][0] === null || tmp[i][j-1][1][0] === '') {
                                vd = 0
                            }
                            tmp[i][j][1][0] = Math.round(value * 100) / 100
                            if (vd !== 0) {
                                tmp[i][j-1][1][1] = Math.round((((va - vd) / vd) * 100) * 100) / 100
                            }
                            else {
                                tmp[i][j-1][1][1] = 0
                            }
                            await updateObject(matricule, (annee), tmp[i][j][1][1], 'taux')
                        }
                        else if (taux === 'taux' && tmp[i][j+1]) {
                            var montant = parseFloat(tmp[i][j][1][0])
                            if (tmp[i][j][1][0] === null || tmp[i][j][1][0] === '') {
                                montant = 0
                            }
                            var tx = parseFloat(tmp[i][j][1][1])
                            if (firstpassage) {
                                tx = Math.round(parseFloat(value) * 100) / 100
                            }
                            
                            tmp[i][j+1][1][0] = Math.round((montant + (montant * tx / 100)) * 100) / 100
                            tmp[i][j][1][1] = tx
                            firstpassage = false
                        }
                    }
                });
            }
        });
        return tmp;
    }

    async function validation()
    {
        var ret = true
        var datas_tmp = props.objet
        var tmpi = 0
        var tab_tmp = {}
        
        while(tmpi <= datas_tmp.length / 10)
        {
            tab_tmp[tmpi] = datas_tmp.slice(0 + (tmpi * 10), 10 + (tmpi * 10))
            const params = {
                'updateGrilleSaisieFiscProsp': true,
                'hypothese': props.hypothese,
                'id_budget': props.id_budget,
                'grilleSaisie': tab_tmp[tmpi]
            }

            await axios.post("https://app-slfinance.fr/api/datas_rei.php", qs.stringify(params))
            .then((res) => {
                console.log(res.data)
            })
            .catch((err) => {
                ret = false
                console.log(err)
            })
            tmpi++;
        }
        return ret;
    }

    return (
        <div>
            <NotificationContainer />
            <Access needConnection={true} type_user={[0]}>

                <div style={{display: 'flex'}}>
                    <button
                        className='shadow-md rounded-md'
                        style={{backgroundColor: purple, color: 'white', fontSize: 14, margin: 20}}
                        onClick={async () => {
                            setDisplayAll(!displayAll)
                        }}
                    >
                        {displayAll?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}>
                            <CloseEye width="15"/>
                            <p style={{marginLeft: 5}}>Cacher les lignes vides</p>
                        </div>
                        :
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}>
                            <OpenEye width="15"/>
                            <p style={{marginLeft: 5}}>Afficher toutes les lignes</p>
                        </div>}
                    </button>

                    <div style={{flex: 1}}></div>

                    <button
                        className='shadow-md rounded-md'
                        style={{backgroundColor: green, color: 'white', fontSize: 14, margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            await setValidButton(true)
                            await validation()
                            .then(() => {
                                NotificationManager.success("Grille sauvegardée", '', 700);
                                setTimeout(() => window.location = window.location.href, 900);
                            })
                        }}
                    >
                        <Check width="12"/>
                        <p style={{marginLeft: 5}}>Valider</p>
                    </button>
                </div>
                
                {props.objet.length == 0 || validButton?
                <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 100}}>
                    <BounceLoader color={purple}/>
                </div>
                :
                <div className='shadow-2xl rounded-md' style={{flex: 1, marginRight: 20, marginLeft: 20, marginBottom: 50, backgroundColor: 'white'}}>
                    <div style={{display: 'flex', flex: 1, borderBottom: '1px solid white', height: 50, backgroundColor: blue, color: 'white'}}>

                        <div style={{flex: 1.5, textAlign: 'center', minWidth: 150}}>
                            <p style={{marginTop: 12, fontSize: 16}}>Intitulé</p>
                        </div>
                        
                        {props.annees.map((annee, j) =>
                        <div key={j} style={{display: 'flex', flex: 1, fontSize: 15, borderLeft: '1px solid white', textAlign: 'center', minWidth: 50, alignItems: 'center'}}>
                            
                            {j > 0?
                            <div style={{flex: 1, fontSize: 14}}>
                                <p>Evol %</p>
                            </div>
                            :null}

                            <div style={{flex: 1.8, fontSize: 16}}>
                                <p>{annee}</p>
                            </div>
                        </div>)}

                    </div>
                    
                    <div style={{flex: 1}}>
                        {/* Listing des lignes */}
                        {props.objet.map((ligne, i) =>
                        ((!emptyLine(ligne) || ligne[0][2] === '1') && !displayAll) || displayAll?
                        <div key={i} style={ligne[0][2] != '1'?{display: 'flex', flex: 1}:{display: 'flex', flex: 1, backgroundColor: greyChapter}}>
                            {/* Affichage de la colone des comptes et noms de compte */}
                            <div style={{flex: 1.5, fontSize: 14, minWidth: 150}}>
                                {ligne[0][2] != '2'?
                                <div style={{marginTop: 4, marginBottom: 4}}>
                                    <p style={{marginLeft: 5, fontWeight: "bold", color: 'black'}}>{ligne[0][1]}</p>
                                </div>
                                :
                                <div style={{marginTop: 4, marginBottom: 4}}>
                                    <p style={{marginLeft: 30, color: 'black'}}>{ligne[0][1]}</p>
                                </div>}
                            </div>

                            {/* Listing des cellules */}
                            {ligne.map((col, j) =>
                                j > 0?
                                <div key={j} style={ligne[0][2] != '1'?{flex: 1, display: 'flex', fontSize: 14, borderLeft: '1px solid white', minWidth: 50}:{flex: 1, display: 'flex', fontSize: 14, borderLeft: '1px solid white', minWidth: 50, backgroundColor: greyChapter}}>
                                    
                                    {/* Input taux */}
                                    {j > 1 && ligne[0][2] !== '1'?
                                    <input
                                    style={{flex: 1, width: 50, border: '0px solid black', paddingRight: 5, textAlign: 'right', fontSize: 12, backgroundColor: blueTx}}
                                    onBlur={async (e) => {
                                        e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                        if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                            e.target.value = parseFloat(e.target.value)
                                            await updateObject(ligne[0][0], ligne[j-1][0], parseFloat(e.target.value).toString(), 'taux')
                                            .then(async(tmp) => {
                                                await updateProduits(tmp)
                                            })
                                        }
                                        e.target.value = ''
                                    }}
                                    placeholder={addSpace(Math.round(ligne[j-1][1][1] * 100) / 100)+'%'}
                                    />
                                    :
                                    j > 1 && ligne[0][2] !== '1' && ligne[0][0].includes('PRD') && ligne[0][0] != 'REIPRD'?
                                    <input
                                    style={{flex: 1, width: 50, border: '0px solid black', paddingRight: 5, textAlign: 'right', fontSize: 12, backgroundColor: blueTx}}
                                    placeholder={addSpace(Math.round(ligne[j-1][1][1] * 100) / 100)+'%'}
                                    disabled
                                    />
                                    :
                                    ligne[0][0] === 'REIPRD' && j > 1?
                                    <div style={{flex: 1, width: 50, paddingRight: 5, textAlign: 'right', display: 'flex', alignItems: 'center'}}>
                                        <p style={{flex: 1, color: 'grey'}}>{addSpace(Math.round(100 * ((ligne[j][1][0] - ligne[j-1][1][0]) / ligne[j-1][1][0]) * 100)/100)}%</p>
                                    </div>
                                    :null}
                                    
                                    {/* Input valeurs */}
                                    {j > 1 && ligne[0][2] !== '1'?
                                    <input
                                    style={{flex: 1.8, width: 50, border: '0px solid black', paddingRight: 5, textAlign: 'right'}}
                                    onBlur={async (e) => {
                                        e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                        if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                            e.target.value = parseFloat(e.target.value)
                                            await updateObject(ligne[0][0], ligne[j][0], parseFloat(e.target.value).toString())
                                            .then(async(tmp) => {
                                                await updateProduits(tmp)
                                            })
                                        }
                                        e.target.value = ''
                                    }}
                                    placeholder={
                                        col[1][0] != ''?
                                            ligne[0][0].includes('TX')?
                                                ligne[0][0] === 'REITXTASCOM'?
                                                    addSpace(Math.round(col[1][0] * 100)/100)
                                                    :
                                                    addSpace(Math.round(col[1][0] * 100)/100)+'%'
                                            :
                                                addSpace((Math.round(col[1][0])), true)
                                        :
                                            ligne[0][0].includes('TX') && ligne[0][0] !== 'REITXTASCOM'?
                                                '0.00%'
                                            :
                                                '0.00'
                                    }
                                    />
                                    :
                                    (j === 1 && ligne[0][2] !== '1' && ligne[0][0] !== 'REIPRD')?
                                    <input
                                    style={{flex: 1.8, width: 50, border: '0px solid black', paddingRight: 5, textAlign: 'right'}}
                                    placeholder={
                                        col[1][0] != ''?
                                            ligne[0][0].includes('TX')?
                                                ligne[0][0] === 'REITXTASCOM'?
                                                    addSpace(Math.round(col[1][0] * 100)/100)
                                                    :
                                                    addSpace(Math.round(col[1][0] * 100)/100)+'%'
                                            :
                                                addSpace((Math.round(col[1][0])), true)
                                        :
                                            ligne[0][0].includes('TX') && ligne[0][0] !== 'REITXTASCOM'?
                                                '0.00%'
                                            :
                                                '0.00'
                                    }
                                    disabled
                                    />
                                    :
                                    ligne[0][0].includes('PRD')?
                                    <div style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', display: 'flex', alignItems: 'center'}}>
                                        <p style={{flex: 1, color: 'grey'}}>{col[1][0] !== ''?addSpace(Math.round(col[1][0]), true):'0.00'}</p>
                                    </div>
                                    :null}
                                </div>
                                :null
                            )}
                        </div>:null
                        )}
                    </div>
                </div>}
            </Access>
        </div>
    );
}

export default GrilleSaisie;