import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import { rescueHtml } from './components/Tools';
import FeedYears from "./components/FeedYears";
import RestartBudgetFromDataGouv from "./components/RestartBudgetFromDataGouv";
import Nn1n2 from "./components/Nn1n2";
import MailingRespSaisie from "./components/MailingRespSaisie";
import MailingDestinatairesBilans from "./components/MailingDestinatairesBilans";
import FeedPopulation from "./components/FeedPopulation";

const Organismepage = (props) =>
{
	// const currentUser = localStorage.getItem("session_token");
    const [budgets, setBudgets] = useState([]);
    const [budget, setBudget] = useState([]);
    // const [user, setUser] = useState({});
    // const [organisme, setOrganisme] = useState('');
    const [nomen, setNomen] = useState(null);
    
    const purple = '#6571FE'

    useEffect(async () =>
    {

        if (!props.user) {
            props.getUser()
        }
        else {
            const params = {
                'getBudgets': true,
                'id': props.user.id_budget
            }
            await axios.post('https://app-slfinance.fr/api/budgets.php', qs.stringify(params))
            .then(async (res) => {
                await setBudget(res.data[0])
            })
            .catch((err) => {
                console.log(err)
            })
            
            /* Récupération du nomen du budget */
            await axios.get("https://app-slfinance.fr/api/config_budget.php?id_budget="+props.user.id_budget)
            .then(async (res) => {
                await res.data.forEach(async element => {
                    if (element[2] === 'nomen') {
                        var tmp = element[3].split(';');
                        await tmp.pop();
                        await setNomen(tmp);
                    }
                });
            })
            .catch((err) => {
                console.log(err)
            })


            await axios.get("https://app-slfinance.fr/api/budgets.php?id_organisme="+props.user.id_organisme+'&list=true')
            .then(async (result) => {
                var tmp = []
                var i = 1
                await result.data.forEach(async budget => {
                    tmp[i] = {
                        'id_budget': budget.id,
                        'budget_annexe': budget.budget_annexe,
                        'nom': rescueHtml(budget.nom),
                        'siret': budget.siret
                    }
                    i++;
                });
                await setBudgets(tmp);
            })
            .catch((err) => {
                console.log(err)
            })

        }
    }, [props.user])


    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    
    const onSubmit = async data => {
        if (data.id_budget)
        {
            const params = {
                'id': props.user.id,
                'id_budget': data.id_budget,
                'updateUser': true
            };
            axios.post('https://app-slfinance.fr/api/connexion.php', qs.stringify(params))
            .then(() => {
                window.location = window.location.href
            })
            .catch((err) => {
                console.log(err)
            })
        }
    };


	return (
        <Access needConnection={true}  type_user={[2, 3]}>
            <Navbar user={props.user} />
            {props.user?
            <div>
                <div style={{display: 'flex', margin: 10}}>
                    
                    <div style={{flex: 1}}>
                        <div style={{flex: 1, margin: 20, display: 'flex'}}>
                            <p style={{color: purple}}>Réglages</p>
                            <p style={{color: purple}}>\</p>
                            <p style={{color: purple, fontWeight: 'bold', marginLeft: 5}}>{rescueHtml(props.user.nom_organisme)}</p>
                        </div>
                    </div>

                    <div style={{flex: 1, textAlign: 'center', marginRight: 10, marginLeft: 10}}>

                        <div style={{margin: 20}}>
                            <p>{props.user.nom_budget}</p>
                        </div>

                        <div style={{flex: 1, display: 'flex'}}>
                            <div style={{flex: 1}}></div>
                            <div>
                                <form style={{flex: 1}} onChange={handleSubmit(onSubmit)}>
                                    <div>
                                        <div style={{margin: 0}}>
                                            <select className='shadow-md' style={{padding: 10}} {...register("id_budget")}>
                                                <option value={''}>Changer de Budget</option>
                                                {budgets.map((budget, id) =>
                                                <option key={id} value={budget['id_budget']}>{budget['nom']}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div style={{flex: 1}}>
                            </div>
                        </div>
                    </div>

                    <div style={{flex: 1, textAlign: 'center', marginRight: 10, marginLeft: 10}}>
                        {nomen?
                        <div style={{margin: 20}}>
                            <p>Maquette comptable actuelle {nomen[0]}{nomen[1]?', anciennement '+nomen[1]:null}</p>
                        </div>
                        :null}
                    </div>

                </div>


                <div style={{flex: 1, display: 'flex', margin: 10, marginTop: 20}}>

                    <div style={{flex: 1, margin: 10}}>
                        {props.user.id_type_users === '1' || props.user.id_type_users === '2'?
                        <Nn1n2 budget={budget} />
                        :null}
                    </div>

                    <div style={{flex: 1, margin: 10}}>
                        {props.user.id_type_users === '1' || props.user.id_type_users === '2'?
                        <MailingDestinatairesBilans budget={budget} />
                        :null}
                    </div>

                    <div style={{flex: 1, margin: 10}}>
                        {props.user.id_type_users === '1' || props.user.id_type_users === '2'?
                        <MailingRespSaisie budget={budget} />
                        :null}
                    </div>
                </div>


                <div style={{flex: 1, display: 'flex', margin: 10, marginTop: 20}}>
                    
                    <div style={{flex: 1, margin: 10}}>
                        {props.user?
                        <FeedPopulation user={props.user}/>
                        :null}
                    </div>

                    <div style={{flex: 1, margin: 10}}>
                        {props.user.id_type_users === '1' || props.user.id_type_users === '2'?
                        <FeedYears budget={budget} />
                        :null}
                    </div>

                    <div style={{flex: 1, margin: 10}}>
                        {props.user.id_type_users === '1'?
                        <RestartBudgetFromDataGouv budget={budget} />
                        :null}
                    </div>
                    
                </div>
            </div>
            :null}
        </Access>
	);
}

export default Organismepage;
