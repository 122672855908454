import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { useForm } from "react-hook-form";
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import Dialog from './Dialog';

const RestartBudgetFromDataGouv = (props) =>
{
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    const [an_deb, setAn_deb] = useState('');
    const [an_fin, setAn_fin] = useState('');
    const green = '#59B44A'

    useEffect(async () => {
    }, [props.budget])


    async function callbackDialog(ret) {
        if (ret === true) {
            var params = {
                'resetBudget': true,
                'siret': props.budget.siret,
                'id_budget': props.budget.id,
                'deb': an_deb,
                'fin': an_fin,
            };
            console.log(params);
            await axios.post('https://app-slfinance.fr/api/budget.php', qs.stringify({}))
            .then((res) => {
                console.log(res.data)
                // if (res.data['status'] === 1) {
                //     NotificationManager.success(res.data['status_message'], '', 2000);
                // }
                // else {
                //     NotificationManager.warning(res.data['status_message'], '', 2000);
                // }
            })
            .catch((err) => {
                console.log(err)
            })
            // setTimeout(() => window.location = window.location.href, 900);
        }
    }

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    const onSubmit = async data => {
        
        if (data.deb !== '' && data.fin !== '') {
            data.deb = parseInt(data.deb);
            data.fin = parseInt(data.fin);
            if (data.deb >= 2014) {
                if (data.fin >= data.deb) {
                    await setAn_deb(data.deb)
                    await setAn_fin(data.fin)
                    await setDialogModalVisible(true)
                }
                else {
                    NotificationManager.warning("L'année de fin doit être supérieure ou égale à l'année de début", '', 3000);
                }
            }
            else {
                NotificationManager.warning("L'année de début doit être supérieure ou égale a 2014", '', 3000);
            }
        }
        else {
            NotificationManager.warning("Veuillez renseigner les champs", '', 3000);
        }
    };

	return (
        <div className='shadow-md' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white', border: '2px solid red'}}>
            
            <Dialog
                setModalVisible={setDialogModalVisible}
                modalVisible={dialogModalVisible}
                callbackDialog={callbackDialog}
                message={"Êtes vous sur de vouloir écraser les données pour "+props.budget.nom+" sur les années sélectionnées ?"}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
                
                <p>Écrase les plages d'années spécifiées dans les champs de saisie pour le budget actuel:</p>
                <p style={{marginTop: 10}}>({props.budget.nom?props.budget.nom:null})</p>

                <div style={{display: 'flex', textAlign: 'center', padding: 10, marginTop: 10}}>
                    <div style={{flex: 1, marginTop: 10}}>
                        <input style={{width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7}} placeholder={an_deb?an_deb:'Année de début'} {...register("deb")} />
                    </div>
                    
                    <div style={{flex: 1, marginTop: 10}}>
                        <input style={{width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7}} placeholder={an_fin?an_fin:'Année de fin'} {...register("fin")} />
                    </div>
                </div>

                <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                    <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                        <Check width="12" />
                        <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Valider" />
                    </div>
                </div>
            </form>
        </div>
	);
}

export default RestartBudgetFromDataGouv;
