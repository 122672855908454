import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useForm } from "react-hook-form";
import 'react-notifications/lib/notifications.css';
import { escapeHtml, rescueHtml, capitalizeFirstLetter } from './Tools';
import { ReactComponent as Trash } from '../Img/icon-trash.svg';
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Add } from '../Img/icon-add.svg';
import Dialog from "./Dialog";

const MailingRespSaisie = (props) =>
{
    const [respSaisie, setRespSaisie] = useState([]);
    const green = '#59B44A'
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    const [userSelected, setUserSelected] = useState(null);
    
    async function callbackDialog(ret) {
        if (ret === true) {
            await suppress(props.budget.id, userSelected)
        }
        await setUserSelected(null);
    }

    useEffect(async () => {
        if (props.budget.id) {
            const url = 'https://app-slfinance.fr/api/responsable_saisie.php';
            const params = {
                'getRespSaisie': true,
                'id_budget': props.budget.id
            }
            await axios.post(url, qs.stringify(params))
            .then((res) => {
                var tmp = []
                res.data.forEach((responsable, i) => {
                    tmp[i] = [responsable.email, responsable.nom, responsable.prenom]
                });
                setRespSaisie(tmp)
            })
        }
    }, [props.budget])


    function suppress(id, email) {
        const url = 'https://app-slfinance.fr/api/responsable_saisie.php';
        const params = {
            'suppressRespSaisie': true,
            'id_budget': id,
            'email': email
        }
        axios.post(url, qs.stringify(params))
        .then((res) => {
            if (res.data['status'] === 1) {
                NotificationManager.success(res.data['status_message'], '', 700);
                setTimeout(() => window.location = window.location.href, 900);
            }
            else {
                NotificationManager.warning(res.data['status_message'], '', 2000);
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    const onSubmit = async (data) => {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (data.nom != '' && data.prenom != '' && data.mail != '') {
            if (data.mail.match(validRegex)) {
                // Si tous les champs sont remplis et que le format du mail est ok allors on ls ajoute en BDD

                const url = 'https://app-slfinance.fr/api/responsable_saisie.php';
                const params = {
                    'addRespSaisie': true,
                    'id_budget': props.budget.id,
                    'email': escapeHtml(data.mail),
                    'nom': escapeHtml(capitalizeFirstLetter(data.nom)),
                    'prenom': escapeHtml(capitalizeFirstLetter(data.prenom))
                }
                axios.post(url, qs.stringify(params))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 700);
                        setTimeout(() => window.location = window.location.href, 900);
                    }
                    else {
                        NotificationManager.warning(res.data['status_message'], '', 2000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
            else {
                NotificationManager.warning("Format de l'adresse email invalide", '', 2000);
            }

        }
        else {
            NotificationManager.warning("Veuillez renseigner tous les champs", '', 2000);
        }
    };

	return (
        <div className='shadow-md' style={{flex: 1, textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>

            <Dialog
                setModalVisible={setDialogModalVisible}
                modalVisible={dialogModalVisible}
                callbackDialog={callbackDialog}
                message={"Confirmez la suppression"}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{display: 'flex', textAlign: 'left', padding: 5}}>
                    <div style={{flex: 1, textAlign: 'center'}}>

                        <p>Responsables de saisie:</p>

                        <div style={{margin: 20, display: 'flex'}}>

                            <div style={{flex: 1}}></div>

                            <div style={{flex: 1}}>
                                <input style={{minWidth: 250, display: 'flex', border: '1px solid #E9ECEF', borderRadius: 5, padding: 7}} placeholder='Nom' {...register("nom")} />
                                <input style={{minWidth: 250, display: 'flex', border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, marginTop: 5}} placeholder='Prenom' {...register("prenom")} />
                                <input style={{minWidth: 250, display: 'flex', border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, marginTop: 5}} placeholder='Email' {...register("mail")} />
                            </div>

                            <div style={{flex: 1}}></div>

                        </div>

                        <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                            <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                                <Add width="12" />
                                <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Ajouter" />
                            </div>
                        </div>

                    </div>
                </div>
            </form>
            {respSaisie.length != 0?
            <div>
                {respSaisie.map((responsable, i) =>
                <div key={i} style={{display: 'flex'}}>
                    <button
                    style={{margin: 5}}
                    onClick={async () => {
                        await setDialogModalVisible(true);
                        await setUserSelected(responsable[0]);
                    }}>
                       <Trash width="20" />
                    </button>
                    <p>{rescueHtml(responsable[0])}</p>
                </div>)}
            </div>:null}
        </div>
	);
}

export default MailingRespSaisie;
