import React, { useEffect, useState } from 'react';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import BounceLoader from "react-spinners/BounceLoader";
import axios from 'axios';
import qs from 'qs';
import { getyears, rescueMonth } from './components/Tools';
import GrilleTableauBord from './Grilles/TableauBord/GrilleTableauBord';
import {NotificationManager} from 'react-notifications';

const TableauDeBord = (props) =>
{
    const [alertCloseWindow, setAlertCloseWindow] = useState(false);
    
    const date = new Date()
    const [mois, setMois] = useState('janvier');
    const [month, setMonth] = useState('Janvier');
    const [annee, setAnnee] = useState();
    const [annees, setAnnees] = useState([]);
    const [objet, setObjet] = useState([]);
    const [DM, setDM] = useState([1]);
    const [aff, setAff] = useState(true);

    const purple = '#6571FE';
    
    useEffect(async() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            if (!annee || !annees) {
                await getyears(props.user.id_budget, 'R')
                .then(async(result) => {
                    await result.push(result[result.length-1]+1);
                    await result.push(result[result.length-1]+1);
                    await result.sort((a, b) => b - a);
                    await setAnnees(result);
                    await setAnnee(date.getFullYear());
                    await get_data_tableau_bord(props.user.id_budget, date.getFullYear());
                })
            }
            else {
                await get_data_tableau_bord(props.user.id_budget, annee);
            }
        }
    }, [props.user, month])


    async function get_data_tableau_bord(id_budget, y)
    {
        /* Récupération des données de tableau de bord pour ce budget pour l'année sélectionnée. */
        const url = 'https://app-slfinance.fr/api/tableau_bord.php';
        const params = {
        	'get_datas': true,
            'id_budget': id_budget,
            'annee': y,
            'mois': mois
        };
        axios.post(url, qs.stringify(params))
        .then(async(res) => {
            var tmp = []
            await res.data.forEach(line => {
                for (var l in line) {
                    if (l.startsWith('DM')) {
                        if (!tmp.includes(parseInt(l.slice(2)))) {
                            tmp[tmp.length] = parseInt(l.slice(2))
                        }
                    }
                }
            });
            if (tmp.length === 0) {
                tmp[0] = 0
            }
            await setDM(tmp.sort(function(a, b){return a - b}))
            await setObjet(res.data)
        })
        .catch(error => {
        	console.log("Access: ", error)
        })
    }

    const setYear = async(event) => {
        await setAnnee(parseInt(event));
    }

    

    async function updateObjet(matricule, parametre, montant, sumBV = false, DMs = false)
    {
        var tmp = objet
        await tmp.forEach((element, i) => {
            if (element.matrice.matricule === matricule) {
                tmp[i][parametre] = montant
            }
        });
        if (sumBV === 'totalVote' && DMs) {
            await totalVote(tmp, matricule, DMs)
        }
        else {
            await setObjet(tmp)
        }
    }

    

    async function totalVote(obj, matricule, DMs)
    {
        await obj.forEach((element, i) => {
            if (element.matrice.matricule === matricule) {
                obj[i]['total_vote'] = obj[i]['budget_primitif']
                for(var param in element)
                {
                    DMs.forEach(DM => {
                        if ('DM'+DM === param) {
                            obj[i]['total_vote'] += element[param]
                        }
                    });
                }
            }
        });
        await setObjet(obj)
    }


	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />
                <div style={{display: 'flex'}}>

                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Tableau de bord</p>
                    </div>

                    {/* Nom du Budget */}
                    {props.user && props.user.nom_budget?
                    <p style={{flex: 1, textAlign: 'center', margin: 20}}>{props.user.nom_budget}</p>
                    :null}

                    <div style={{flex: 1, display: 'flex'}}>
                        {/* Selection de l'annee */}
                        <select
                        className='shadow-md'
                        style={{flex: 1, padding: 5, margin: 15}}
                        value={annee}
                        onChange={async(e) => {

                            if (alertCloseWindow === false) {
                                await setYear(e.target.value)
                                await get_data_tableau_bord(props.user.id_budget, e.target.value)
                            }
                            else {
                                e.target.value = annee;
                                await setAff(!aff)
                                NotificationManager.warning("Validez la grille avant de sortir de cette page", '', 3000);
                            }
                        }}>
                            {annees.map((year, i) => 
                            <option key={i} value={year}>{year}</option>
                            )}
                        </select>

                        {/* Selection du mois */}
                        <select className='shadow-md' style={{flex: 1, padding: 5, margin: 15}}
                        onChange={async(e) => {
                            if (alertCloseWindow === false) {
                                await setMois(e.target.value)
                                await rescueMonth(e.target.value)
                                .then(async(result) => {
                                    await setMonth(result)
                                    await setAff(!aff)
                                })
                            }
                            else {
                                e.target.value = mois;
                                await setAff(!aff)
                                NotificationManager.warning("Validez la grille avant de sortir de cette page", '', 3000);
                            }
                        }}>
                            <option value='janvier'>Janvier</option>
                            <option value='fevrier'>Février</option>
                            <option value='mars'>Mars</option>
                            <option value='avril'>Avril</option>
                            <option value='mai'>Mai</option>
                            <option value='juin'>Juin</option>
                            <option value='juillet'>Juillet</option>
                            <option value='aout'>Août</option>
                            <option value='septembre'>Septembre</option>
                            <option value='octobre'>Octobre</option>
                            <option value='novembre'>Novembre</option>
                            <option value='decembre'>Décembre</option>
                        </select>
                    </div>
                </div>
                

                {objet.length != 0?
                <GrilleTableauBord objet={objet} annee={annee} mois={mois} frontMonth={month} user={props.user} updateObjet={updateObjet} DM={DM} setDM={setDM} alertCloseWindow={alertCloseWindow} setAlertCloseWindow={setAlertCloseWindow} />
                :
                <div style={{display: 'flex', margin: 50}}>
                    <div style={{flex: 1}}></div>
                        <BounceLoader color={purple} />
                    <div style={{flex: 1}}></div>
                </div>
                }

            </Access>
        </div>
	);
}

export default TableauDeBord;
