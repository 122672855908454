import React, { useEffect, useState } from 'react';
import Access from "../../components/Access";
import axios from 'axios';
import qs from 'qs';
import BounceLoader from "react-spinners/BounceLoader";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import AddlineSaisie from './AddlineSaisie';
import { addSpace, exportGrilleSaisieCsv, rescueHtml } from '../../components/Tools';
import { ReactComponent as Add } from '../../Img/icon-add.svg';
import { ReactComponent as Check } from '../../Img/icon-check.svg';
import { ReactComponent as Download } from '../../Img/icon-download.svg';
import { ReactComponent as CloseEye } from '../../Img/icon-close-eye.svg';
import { ReactComponent as OpenEye } from '../../Img/icon-open-eye.svg';

const GrilleSaisie = (props) =>
{
    let fctinv = window.location.pathname.split('/')[3];
    let recdep = window.location.pathname.split('/')[4];
    const [validButton, setValidButton] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [alertCloseWindow, setAlertCloseWindow] = useState(false);
    const [souscomptes, setSouscomptes] = useState(true);
    const heightObject = window.innerHeight/1.9

    const greyChapter = '#DBDBDB'
    const purple = '#6571FE'
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'
    const green = '#59B44A'

    async function recalcule(tmp)
    {
        var i = 0
        while(tmp[i])
        {
            if (tmp[i][0][3] !== '3' && !tmp[i][0][0].includes('OOB') && !tmp[i][0][0].includes('RAR') && !tmp[i][0][0].includes('001') && !tmp[i][0][0].includes('002'))
            {
                var j = 1
                while (tmp[i][j])
                {
                    await sumChapters(tmp[i][0][0], tmp[i][j][0], tmp);
                    j++;
                }
            }
            i++;
        }
        await totalreelles(tmp)
    }


    useEffect(async () => {
        if (props.objet.length != 0) {
            await recalcule(props.objet);
        }
    }, [props.objet])


    if (alertCloseWindow === true) {
        window.addEventListener('beforeunmount', function (e) {
            e.preventDefault();
            e.returnValue = '';
        });
    }

    async function totalreelles(tmp)
    {
        await props.annees.forEach(annee => {
            var total = 0
            tmp.forEach((line) => {
                if (line[0][3] === '3') {
                    line.forEach((cel) => {
                        if (cel[0] === annee) {
                            if (cel[1][0] !== '0' && cel[1][0] !== 0) {
                                total = total + parseFloat(cel[1][0]);
                            }
                        }
                    });
                }
            });
            tmp.forEach((line, i) => {
                if (line[0][0].includes('REEL')) {
                    line.forEach((cel, j) => {
                        if (cel[0] === annee) {
                            if ((Math.round(total*100)/100) != tmp[i][j][1][0]) {
                                tmp[i][j][1][0] = Math.round(total*100)/100;
                                tmp[i][j][2] = true;
                            }
                        }
                    });
                }
            });
        });
        await ligneTotal(tmp)
    }

    async function ligneTotal(tmp)
    {
        await props.annees.forEach(annee => {
            var total = 0
            tmp.forEach((line) => {
                if (line[0][0].includes('REEL') || line[0][0].includes('OOB')) {
                    line.forEach((cel) => {
                        if (cel[0] === annee) {
                            if (cel[1][0] !== '0' && cel[1][0] !== 0) {
                                total = total + parseFloat(cel[1][0]);
                            }
                        }
                    });
                }
            });
            tmp.forEach((line, i) => {
                if (line[0][0].includes('OBNET')) {
                    line.forEach((cel, j) => {
                        if (cel[0] === annee) {
                            if ((Math.round(total*100)/100) != tmp[i][j][1][0]) {
                                tmp[i][j][1][0] = Math.round(total*100)/100;
                                tmp[i][j][2] = true;
                            }
                        }
                    });
                }
            });
        });
        // if (fctinv === 'inv' && recdep === 'depense') {
        //     await totalDepensesEquipement(tmp);
        // }
        // else {
            await props.setObjet(tmp);
            await props.setAff(!props.aff);
        // }
    }

    // async function totalDepensesEquipement(tmp)
    // {
        // await props.annees.forEach(annee => {
        //     var total = 0;
        //     tmp.forEach((line) => {
        //         if (line[0][1] === '20' || line[0][1] === '21' || line[0][1] === '23' || line[0][1] === '45') {
        //             line.forEach((cel) => {
        //                 if (cel[0] === annee) {
        //                     if (cel[1][0] !== '0' && cel[1][0] !== 0) {
        //                         total = total + parseFloat(cel[1][0]);
        //                     }
        //                 }
        //             });
        //         }
        //     });
        //     tmp.forEach((line, i) => {
        //         if (line[0][0].includes('TOTEQUIP')) {
        //             line.forEach((cel, j) => {
        //                 if (cel[0] === annee) {
        //                     if ((Math.round(total*100)/100) != tmp[i][j][1][0]) {
        //                         tmp[i][j][1][0] = Math.round(total*100)/100;
        //                         tmp[i][j][2] = true;
        //                     }
        //                 }
        //             });
        //         }
        //     });
        // });
        // await props.setObjet(tmp);
        // await props.setAff(!props.aff);
    // }
    
    async function sumChapters(chapterMatricule, an, tmp)
    {
        var chapterCount = 0;
        await tmp.forEach((line, i) => {
            if (tmp[i][0][4] === chapterMatricule) {
                var j = 1;
                while(tmp[i][j])
                {
                    if (line[j][0] === an) {
                        var montant = 0;
                        if (parseFloat(tmp[i][j][1][0])) {
                            montant = parseFloat(tmp[i][j][1][0]);
                        }
                        chapterCount += montant;
                    }
                    j++;
                }
            }
        });
        await tmp.forEach((line, i) => {
            if (line[0][0] === chapterMatricule)
            {
                var j = 0;
                while(tmp[i][j])
                {
                    if (tmp[i][j][0] === an)
                    {
                        if (chapterCount != tmp[i][j][1][0]) {
                            tmp[i][j][1][0] = chapterCount;
                            tmp[i][j][2] = true;
                        }
                    }
                    j++;
                }
            }
        });

        return tmp;
    }

    async function updateObjetLine(matricule, an, montant = '', manuel = true)
    {
        var tmp = props.objet;
        var i = 0;
        while(i < tmp.length)
        {
            if (tmp[i][0][0] === matricule)
            {
                var chapterMatricule = tmp[i][0][4];
                var j = 1;
                while(tmp[i][j])
                {
                    if (an === tmp[i][j][0])
                    {
                        if (montant === '0') {
                            montant = 0;
                        }
                        if (montant != tmp[i][j][1][0]) {
                            tmp[i][j][1][0] = montant;
                            tmp[i][j][1][1] = '1';
                            tmp[i][j][2] = true;
                        }
                    }
                    j++;
                }
            }
            i++;
        }
        await setAlertCloseWindow(true);
        return sumChapters(chapterMatricule, an, tmp);
    }

    async function first_validation()
    {
        var ret = true;
        var tab_tmp = props.objet;
        var cut_tab = {};
        var tmpi = 0

        while(tmpi <= tab_tmp.length / 10)
        {
            cut_tab[tmpi] = tab_tmp.slice((tmpi * 10), 10 + (tmpi * 10))
            const params = {
                'updateGrilleSaisieRetro': true,
                'fctinv': props.fctinv,
                'recdep': props.recdep,
                'hypothese': props.hypothese,
                'id_budget': props.id_budget,
                'grilleSaisie': cut_tab[tmpi]
            }
                
            await axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify(params))
            // .then((ret) => {
            //     console.log(ret.data);
            // })
            .catch((err) => {
                ret = false;
                console.log(err);
            })
            tmpi++;
        }

        return ret;
    }

    async function validation()
    {
        var ret = true;
        var tab_tmp = [];
        var cut_tab = {};
        var tmpi = 0

        await props.objet.forEach(element => {
            element.forEach((el, k) => {
                if (k > 0 && el[2] == true && (tab_tmp[tab_tmp.length-1]) != element) {
                    tab_tmp.push(element);
                }
            });
        });

        while(tmpi <= tab_tmp.length / 10)
        {
            cut_tab[tmpi] = tab_tmp.slice((tmpi * 10), 10 + (tmpi * 10))
            const params = {
                'updateGrilleSaisieRetro': true,
                'fctinv': props.fctinv,
                'recdep': props.recdep,
                'hypothese': props.hypothese,
                'id_budget': props.id_budget,
                'grilleSaisie': cut_tab[tmpi]
            }
                
            await axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify(params))
            .then((ret) => {
                console.log(ret.data);
            })
            .catch((err) => {
                ret = false;
                console.log(err);
            })
            tmpi++;
        }

        return ret;
    }

	return (
        <div>
            <NotificationContainer />
            <Access needConnection={true} type_user={[0]}>
                {props.objet.length == 0 || validButton?
                <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 100}}>
                    <BounceLoader color={purple} />
                </div>
                :
                <div style={{flex: 1}}>
                    <AddlineSaisie modalVisible={modalVisible} setModalVisible={setModalVisible} annees={props.annees} id_budget={props.id_budget} fctinv={props.fctinv} recdep={props.recdep} hypothese={props.hypothese} />
                    
                    <div style={{display: 'flex'}}>
                        
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: green, color: 'white', marginLeft: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                            onClick={async () => {
                                setModalVisible(true)
                                await validation()
                                .then(() => {
                                    NotificationManager.success("Grille sauvegardée", '', 700);
                                })
                            }}
                        >
                            <Add width="12"/>
                            <p style={{marginLeft: 5}}>Nouvelle ligne</p>
                        </button>
                        
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: purple, color: 'white', marginLeft: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                            onClick={() => {
                                setSouscomptes(!souscomptes);
                            }}
                        >
                            {!souscomptes?
                            <OpenEye width="12"/>
                            :
                            <CloseEye width="12"/>
                            }
                            <p style={{marginLeft: 5}}>{!souscomptes?'Afficher':'Cacher'} les sous comptes</p>
                        </button>

                        <div style={{flex: 1}}></div>
                       

                        {/* <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: purple, color: 'white', marginRight: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                            onClick={async () => {
                                const filename = rescueHtml(props.nom_organisme.replace(' ', '_')+"_"+props.nom_budget.replace(' ', '_')+" RETRO_"+fctinv+"_"+recdep).replace("'", "").toUpperCase();
                                // await console.log(props.objet, props.annees, filename, props.nom_organisme, props.nom_budget)
                                await exportGrilleSaisieCsv(props.objet, props.annees, filename, props.nom_organisme, props.nom_budget)
                            }}
                        >
                            <Download width="12"/>
                            <p style={{marginLeft: 5}}>Exporter Excel</p>
                        </button> */}

                        {props.user.id_type_users === '1'?
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: blue, color: 'white', marginRight: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                            onClick={async () => {
                                await recalcule(props.objet)
                                setValidButton(true)
                                await setAlertCloseWindow(false)
                                await props.setAff(!props.aff)
                                await first_validation()
                                .then(() => {
                                    NotificationManager.success("Grille sauvegardée", '', 700);
                                    setTimeout(() => window.location = window.location.href, 900);
                                })
                            }}
                        >
                            <Check width="12"/>
                            <p style={{marginLeft: 5}}>Première validation</p>
                        </button>
                        :null}


                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: green, color: 'white', marginRight: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                            onClick={async () => {
                                setValidButton(true)
                                await setAlertCloseWindow(false)
                                await props.setAff(!props.aff)
                                await validation()
                                .then(() => {
                                    NotificationManager.success("Grille sauvegardée", '', 700);
                                    setTimeout(() => window.location = window.location.href, 900);
                                })
                            }}
                        >
                            <Check width="12"/>
                            <p style={{marginLeft: 5}}>Valider</p>
                        </button>
                        
                    </div>
                    
                    {/* Tableau Grille Saisie */}
                    <div style={{flex: 1}}>
                        <div className='table-auto shadow-2xl rounded-md' style={{flex: 1, margin: 20, backgroundColor: 'white'}}>
                            
                            <div style={{flex: 1, display: 'flex', overflowY: 'scroll', backgroundColor: blue, color: 'white'}}>
                                <div style={{flex: 3, textAlign: 'center', minWidth: 150}}>
                                    <p style={{fontSize: 18, margin: 10}}>Compte</p>
                                </div>
                                {props.annees.map((annee, j) =>
                                    <div key={j} style={{flex: 1, fontSize: 15, borderLeft: '1px solid white', textAlign: 'center', minWidth: 50}}>
                                        <p style={{fontSize: 18, margin: 10}}>{annee}</p>
                                    </div>
                                )}
                            </div>


                            <div style={{flex: 1, overflowY: 'scroll', maxHeight: heightObject}}>
                            {props.objet.map((ligne, i) =>
                            souscomptes || (!souscomptes && ligne[0][3] !== '3')?
                                <div key={i} style={ligne[0][3] !== '3' && !ligne[0][0].includes('OOB') && !ligne[0][0].includes('RAR') && !ligne[0][0].includes('R00') && !ligne[0][0].includes('D00')?{display: 'flex', flex: 1, minHeight: 35, borderTop: '1px solid white', backgroundColor: greyChapter}:i % 2 !== 0?{display: 'flex', flex: 1, minHeight: 35}:{display: 'flex', flex: 1, minHeight: 35, backgroundColor: smoothGrey}}>
                                    
                                    {/* Numero de compte et intitulé */}
                                    <div style={{display: 'flex', flex: 3, fontSize: 14, minWidth: 150}}>
                                        {ligne[0][3] !== '3' && !ligne[0][0].includes('OOB') && !ligne[0][0].includes('RAR') && !ligne[0][0].includes('R00') && !ligne[0][0].includes('D00')?
                                        <div style={{display: 'flex', margin: 5}}>
                                            <p style={{fontWeight: "bold", color: 'red'}}>{ligne[0][1] != ''?ligne[0][1]:'-'}</p>
                                            <p style={{flex: 1, marginLeft: 5, fontWeight: "bold",color: 'black'}}>{ligne[0][2]}</p>
                                        </div>
                                        :
                                        <div style={{display: 'flex', margin: 5}}>
                                            <p style={{color: 'red', marginLeft: 30}}>{ligne[0][1] != ''?ligne[0][1]:'-'}</p>
                                            <p style={{flex: 1, marginLeft: 5, color: 'black'}}>{ligne[0][2]}</p>
                                        </div>}
                                    </div>

                                    {ligne.map((col, j) =>
                                    j != 0?
                                    ligne[0][3] !== '3' && !ligne[0][0].includes('OOB') && !ligne[0][0].includes('RAR') && !ligne[0][0].includes('R00') && !ligne[0][0].includes('D00')?
                                    <div key={j} style={{flex: 1, display: 'flex', fontSize: 12, borderLeft: '1px solid white', minWidth: 50}}>
                                        {/* cellules pour les chapitres grisées */}
                                        <input
                                        type="float"
                                        style={col[1][0] === undefined?{flex: 1, width: 50, border: '0px solid black', textAlign: 'right', fontWeight: "bold"}:col[1][1] === '0'?{flex: 1, width: 50, border: '0px solid black', textAlign: 'right', fontWeight: "bold"}:{flex: 1, width: 50, border: '0px solid black', padding: 5, textAlign: 'right', fontWeight: "bold"}}
                                        placeholder={addSpace(Math.round(col[1][0] * 100) / 100)}
                                        disabled
                                        />
                                    </div>
                                    :
                                    <div key={j} style={{flex: 1, display: 'flex', fontSize: 12, borderLeft: '1px solid white', minWidth: 50}}>
                                        {/* cellules pour les lignes non grisées */}
                                        <input
                                        type="float"
                                        style={i%2===0?{flex: 1, textAlign: 'right', borderLeft: '1px solid white', width: 100, padding: 2, backgroundColor: smoothGrey}:{flex: 1, textAlign: 'right', borderLeft: '1px solid '+smoothGrey, width: 100, padding: 2}}
                                        placeholder={addSpace(Math.round(col[1][0] * 100) / 100)}
                                        onBlur={async (e) => {
                                            e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                            if (e.target.value !== '' && (parseFloat(e.target.value)))
                                            {
                                                e.target.value = parseFloat(e.target.value)
                                                await updateObjetLine(ligne[0][0], col[0], parseFloat(Math.round(e.target.value * 100) / 100).toString())
                                                .then(async(tmp) => {
                                                    await recalcule(tmp)
                                                })
                                                // await props.setAff(!props.aff)
                                            }
                                            else if (e.target.value === '0')
                                            {
                                                await updateObjetLine(ligne[0][0], col[0], parseFloat(e.target.value).toString(), false)
                                                .then(async(tmp) => {
                                                    await recalcule(tmp)
                                                })
                                                // await props.setAff(!props.aff)
                                            }
                                            e.target.value = ''
                                        }}
                                        />
                                    </div>
                                    :null
                                )}
                                </div>
                            :null
                            )}
                            </div>

                        </div>
                    </div>
                </div>}
            </Access>
        </div>
	);
}

export default GrilleSaisie;
