import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import GrilleSaisieRetro from "./Grilles/Retrospective/Fiscalite/GrilleSaisie";
import GrilleSaisieProsp from "./Grilles/Prospective/Fiscalite/GrilleSaisie";
import { ReactComponent as Copy } from './Img/icon-copy.svg';
import CopyHyp from "./components/copyHyp";
import { rescueHtml } from './components/Tools.js';

const SaisieFisc = (props) =>
{
    const [modalVisible, setModalVisible] = useState(false);
    const [objet, setObjet] = useState([]);
    const [aff, setAff] = useState(false);
    const [annees, setAnnees] = useState([]);
    const [hypothese, setHypothese] = useState(null);

    const purple = '#6571FE'

    let retro_prosp = window.location.pathname.split('/')[2];

    
    async function searchCel(matricule, annee, objet)
    {
        var ret = false
        await objet.forEach(line => {
            if (line[0][0] === matricule) {
                line.forEach(year => {
                    if (year[0] === annee) {
                        ret = parseFloat(year[1][0])
                    }
                });
            }
        });
        return ret
    }

    async function updateProduits(objet, annee)
    {
        await objet.forEach(async (line, j) => {
            if (line[0][3] != '') {
                await line.forEach(async (year, i) => {
                    if (i != 0 && year[0] === annee) {
                        await searchCel(line[0][3].split(';')[0], annee, objet)
                        .then(async (base) => {
                            if (base) {
                                await searchCel(line[0][3].split(';')[1], annee, objet)
                                .then(async (taux) => {
                                    if (taux) {
                                        objet[j][i][1][0] = (Math.round(base * taux) / 100)
                                        objet[j][i][1][1] = '0'
                                    }
                                })
                            }
                        })
                        await setObjet(objet)
                        await setAff(!aff)
                    }
                });
            }
        });
    }

    function capitalize(s)
    {
        return s[0].toUpperCase() + s.slice(1);
    }

    async function getData_rei(id_budget, liste_an, hyp)
    {
        var params = {}
        if (hyp === 'R') {
            params = {
                'getGrilleSaisieRetroFisc': true,
                'id_budget': id_budget,
                'hypothese': hyp,
                'an_deb': liste_an[0],
                'an_fin': liste_an[liste_an.length-1]
            }
        }
        else {
            params = {
                'getGrilleSaisieProspFisc': true,
                'id_budget': id_budget,
                'hypothese': hyp,
                'an_deb': liste_an[0],
                'an_fin': liste_an[liste_an.length-1]
            }
        }

        await axios.post('https://app-slfinance.fr/api/datas_rei.php', qs.stringify(params))
        .then(async (ret) => {
            console.log(ret.data)
            if (ret.data && ret.data.length != [])
            {
                var tmp = []
                var i = 0
                ret.data.forEach(element => {
                    const infos = [element[0].matricule, element[0].nom, element[0].indentation, element[0].calcul]
                    tmp[i] = [infos]
                    var j = 1
                    element[1].forEach(annee => {
                        tmp[i][j] = annee
                        j++;
                    });
                    i++;
                });
                await setObjet(tmp)

                await annees.forEach(async year => {
                    await updateProduits(objet, year)
                });
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }


    useEffect(async () => {
        if (props.user === null) {
            await props.getUser()
        }
        else {
            if (props.user.budget_annexe == '1')
            {
                window.location = window.location.origin;
            }
            else {

                setHypothese('h'+props.user.hypothese)
                // Recuperation des annees de retro et prosp
                let retro_an_deb = ''
                let retro_an_fin = ''
                let prosp_an_fin = ''
                await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
                .then((result) => {
                    result.data.forEach(element => {
                        if (element[2] === 'retro_an_deb') {
                            retro_an_deb = parseInt(element[3])
                        }
                        if (element[2] === 'retro_an_fin') {
                            retro_an_fin = parseInt(element[3])
                        }
                        if (element[2] === 'prosp_an_fin') {
                            prosp_an_fin = parseInt(element[3])
                        }
                    });
                }).catch((err) => {console.log(err)})
        
                if (retro_an_deb === '' || retro_an_fin === '' || prosp_an_fin === '') {
                    await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
                    .then(async function (res) {
                        if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                            if (retro_an_deb === '') {
                                retro_an_deb = parseInt(res.data.retro_an_deb)
                            }
                            if (retro_an_fin === '') {
                                retro_an_fin = parseInt(res.data.retro_an_fin)
                            }
                            if (prosp_an_fin === '') {
                                prosp_an_fin = parseInt(res.data.prosp_an_fin)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
                var liste_an = []
                var i = 0
                if (retro_prosp === 'retrospective') {
                    while (retro_an_deb <= retro_an_fin)
                    {
                        liste_an[i] = parseInt(retro_an_deb)
                        retro_an_deb++;
                        i++;
                    }
                }
                else {
                    while (retro_an_fin <= prosp_an_fin)
                    {
                        liste_an[i] = parseInt(retro_an_fin)
                        retro_an_fin++;
                        i++;
                    }
                }
                await setAnnees(liste_an)
                // Recuperation de la grille de saisie Fiscalité
                if (retro_prosp === 'retrospective') {
                    await getData_rei(props.user.id_budget, liste_an, 'R')
                }
                else {
                    await getData_rei(props.user.id_budget, liste_an, 'h'+props.user.hypothese)
                }
            }
        }
    }, [props.user])

	return (
        <div>
            <Navbar user={props.user} />
            <Access needConnection={true} type_user={[0]}>
                <div style={{display: 'flex'}}>

                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <p>{capitalize(retro_prosp)}</p>
                        <p style={{marginLeft: 5}}>\</p>
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Fiscalité</p>
                    </div>
                    {props.user?
                    <p style={{flex: 1, textAlign: 'center', margin: 20}}>{props.user.nom_budget}</p>
                    :null}
                    
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1}}></div>

                        {retro_prosp !== 'retrospective'?
                        <div style={{marginLeft: 20}}>
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: purple, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                onClick={() => {
                                    setModalVisible(!modalVisible)
                                }}
                            >
                                <Copy width="12"/>
                                <p style={{marginLeft: 5}}>Copier une hypothèse</p>
                            </button>
                        </div>
                        :null}
                    </div>
                </div>


                {props.user && modalVisible?
                <CopyHyp modalVisible={modalVisible} setModalVisible={setModalVisible} hypothese={hypothese} id_budget={props.user.id_budget} fiscalite={true}/>
                :null}


                {objet.length != 0 && props.user.id_budget && annees.length != 0?
                retro_prosp === 'retrospective'?
                <GrilleSaisieRetro
                hypothese={hypothese}
                id_budget={props.user.id_budget}
                objet={objet}
                annees={annees}
                setObjet={setObjet}
                setAff={setAff}
                aff={aff}
                updateProduits={updateProduits}
                nom_organisme={rescueHtml(props.user.nom_organisme)}
                nom_budget={props.user.nom_budget}
                />
                :
                <GrilleSaisieProsp
                hypothese={hypothese}
                id_budget={props.user.id_budget}
                objet={objet}
                annees={annees}
                setObjet={setObjet}
                setAff={setAff}
                aff={aff}
                updateProduits={updateProduits}
                nom_organisme={rescueHtml(props.user.nom_organisme)}
                nom_budget={props.user.nom_budget}
                />
                :null}


            </Access>
        </div>
	);
}

export default SaisieFisc;
