import React, { useEffect, useState } from 'react';
import LogoSLF from '../Img/logo_SLF.png';
import Checked from '../Img/icon-checked.png';
import { rescueHtml, addSpace } from "../components/Tools";


const Page29RecapFP = (props) =>
{
    const smoothGrey = '#f4f4f4'
    const blue = '#069BD3'
    
    const [total, setTotal] = useState(0)
    const [total_dep_fct, setTotal_dep_fct] = useState(0)
    const [total_rec_fct, setTotal_rec_fct] = useState(0)
    const [total_dep_inv, setTotal_dep_inv] = useState(0)
    const [total_rec_inv, setTotal_rec_inv] = useState(0)

    useEffect(async () => {

        var montant_total = 0;
        var dep_fct = 0;
        var rec_fct = 0;
        var dep_inv = 0;
        var rec_inv = 0;
        await props.listeProjets.forEach(proj => {
            montant_total = (montant_total + parseFloat(proj[5]));
            dep_fct = (dep_fct + parseFloat(proj[9]));
            rec_fct = (rec_fct + parseFloat(proj[10]));
            dep_inv = (dep_inv + parseFloat(proj[11]));
            rec_inv = (rec_inv + parseFloat(proj[12]));
        });
        await setTotal(Math.round(montant_total*100)/100);
        await setTotal_dep_fct(Math.round(dep_fct*100)/100);
        await setTotal_rec_fct(Math.round(rec_fct*100)/100);
        await setTotal_dep_inv(Math.round(dep_inv*100)/100);
        await setTotal_rec_inv(Math.round(rec_inv*100)/100);
    }, [])

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={LogoSLF} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%', textAlign: 'center'}}>
                <div style={{flex: 1, margin: 25, border: '1px solid '+blue, borderRadius: 5, fontSize: 12}}>
                    <div style={{display: 'flex', backgroundColor: blue, color: 'white', fontSize: 14}}>
                        <div style={{width: 50, textAlign: 'center', justifyContent: 'center'}}>
                            <p>Nº</p>
                        </div>
                        <div style={{width: 300, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>Intitulés des projets</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>Montants</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>Dépenses de fonctionnement</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>Recettes de fonctionnement</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>Dépenses d'investissement</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>Recettes d'investissement</p>
                        </div>
                        <div style={{flex: 0.2, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>H1</p>
                        </div>
                        <div style={{flex: 0.2, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>H2</p>
                        </div>
                        <div style={{flex: 0.2, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>H3</p>
                        </div>
                    </div>

                    {props.listeProjets.map((projet, i) =>
                    i<15?
                    <div key={i} style={i%2?{flex: 1, display: 'flex', borderTop: '1px solid white', backgroundColor: smoothGrey}:{flex: 1, display: 'flex', borderTop: '1px solid white'}}>
                        <div style={{width: 50, textAlign: 'center', justifyContent: 'center'}}>
                            <p>{projet[1] !== null?projet[1]:'Nouveau projet'}</p>
                        </div>
                        <div style={i%2?{width: 300, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid white'}:{width: 300, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{projet[2] !== null?rescueHtml(projet[2]):''}</p>
                        </div>
                        <div style={i%2?{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{projet[5] !== null?addSpace(Math.round(projet[5]), true):'0,00'} €</p>
                        </div>
                        <div style={i%2?{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{projet[9] !== null?addSpace(Math.round(projet[9]), true):'0,00'} €</p>
                        </div>
                        <div style={i%2?{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{projet[10] !== null?addSpace(Math.round(projet[10]), true):'0,00'} €</p>
                        </div>
                        <div style={i%2?{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{projet[11] !== null?addSpace(Math.round(projet[11]), true):'0,00'} €</p>
                        </div>
                        <div style={i%2?{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{projet[12] !== null?addSpace(Math.round(projet[12]), true):'0,00'} €</p>
                        </div>
                        <div style={i%2?{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <div style={{height: '25%'}}></div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                {projet[6] === '1'?<img style={{width: 15}} src={Checked} />:null}
                                <div style={{flex: 1}}></div>
                            </div>
                        </div>
                        <div style={i%2?{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <div style={{height: '25%'}}></div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                {projet[7] === '1'?<img style={{width: 15}} src={Checked} />:null}
                                <div style={{flex: 1}}></div>
                            </div>
                        </div>
                        <div style={i%2?{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid white'}:{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <div style={{height: '25%'}}></div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                {projet[8] === '1'?<img style={{width: 15}} src={Checked} />:null}
                                <div style={{flex: 1}}></div>
                            </div>
                        </div>
                    </div>
                    :
                    i == 15?
                    <div key={i} style={i%2?{flex: 1, display: 'flex', borderTop: '1px solid white', backgroundColor: smoothGrey}:{flex: 1, display: 'flex', borderTop: '1px solid white'}}>
                        <div style={{width: 50, textAlign: 'center', justifyContent: 'center'}}>
                            <p style={{padding: 5}}>[...]</p>
                        </div>
                        <div style={i%2?{flex: 1, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid white'}:{width: 300, textAlign: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p style={{padding: 5}}>[ Liste incomplète par manque de place ]</p>
                        </div>
                    </div>
                    :null)}

                    <div style={{flex: 1, display: 'flex', borderTop: '1px solid '+smoothGrey, fontSize: 15}}>
                        
                        <div style={{width: 350, justifyContent: 'center', textAlign: 'center'}}>
                            <p>Total</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{addSpace(Math.round(total), true)} €</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{addSpace(Math.round(total_dep_fct), true)} €</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{addSpace(Math.round(total_rec_fct), true)} €</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{addSpace(Math.round(total_dep_inv), true)} €</p>
                        </div>
                        <div style={{flex: 1, textAlign: 'right', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p>{addSpace(Math.round(total_rec_inv), true)} €</p>
                        </div>
                        <div style={{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p style={{width: 15}}></p>
                        </div>
                        <div style={{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p style={{width: 15}}></p>
                        </div>
                        <div style={{flex: 0.2, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid '+smoothGrey}}>
                            <p style={{width: 15}}></p>
                        </div>
                        
                    </div>
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page29RecapFP;