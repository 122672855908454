import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import qs from 'qs';
import { escapeHtml, rescueHtml } from './Tools';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ReactComponent as Add } from '../Img/icon-add.svg';

const InvitUser = () =>
{
    const [organismes, setOrganismes] = useState([]);
    const [type_users, setType_users] = useState([]);
    const green = '#59B44A'

    useEffect(() => {
        axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true}))
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: rescueHtml(res.data[i].nom)
                }
            }
            setOrganismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })

        axios.get("https://app-slfinance.fr/api/type_users.php")
        .then((result) => {
            var tmp2 = [];
            for (let i = 0; i < result.data.length; i++) {
                tmp2[i] = {
                    id: result.data[i].id,
                    nom: result.data[i].type_user
                }
            }
            setType_users(tmp2);
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
       return result;
    }
    

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    
    const onSubmit = data => {
        const token = makeid(80)
        if (data.user_type === "") {
            data.user_type = "1"
        }
        if (data.mail)
        {
            if (data.user_type === "2" && data.organisme === "")
            {
                NotificationManager.warning('Veuillez renseigner l\'organisme', '', 2000);
            }
            else {
                if ((data.user_type === '1' && data.mail.includes('strategies-locales.fr')) || (data.user_type !== '1')) {
                    const url = 'https://app-slfinance.fr/api/users.php';
                    const params = {
                        'createUser': true,
                        'email': escapeHtml(data.mail),
                        'token': token,
                        'id_type_users': data.user_type,
                        'id_organisme': data.organisme,
                        'sendmail': true,
                        'url': window.location.host
                    }
                    axios.post(url, qs.stringify(params))
                    .then((res) => {
                        console.log(res.data)
                        if (res.data['status'] === 1) {
                            NotificationManager.success('Utilisateur invité', '', 700);
                            setTimeout(() => window.location = window.location.href, 900);
                        }
                        else {
                            NotificationManager.warning(res.data['status_message'], '', 2000);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
                else {
                    NotificationManager.warning('Seul les utilisateurs SL peuvent avoir ce status', '', 5000);
                }
            }
        }
        else {
            NotificationManager.warning("Veuillez renseigner l'email", '', 2000);
        }
    };

	return (
        <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>
            <NotificationContainer />
            <p>Inviter un utilisateur :</p>
            <form style={{textAlign: 'left'}} onSubmit={handleSubmit(onSubmit)}>
                <div style={{textAlign: 'center'}}>
                    <div style={{margin: 10}}>
                        <input className='shadow-md' style={{width: 250}} placeholder='Email' {...register("mail")} />
                    </div>
                    <div style={{margin: 10}}>
                        <select className='shadow-md' style={{width: 250, padding: 5}} {...register("user_type")}>
                            {type_users.map((user) => 
                            <option key={user['id']} value={user['id']}>{user['nom']}</option>
                            )}
                        </select>
                    </div>

                    <div style={{margin: 10}}>
                        <select className='shadow-md' style={{width: 250, padding: 5}} {...register("organisme")}>
                            <option value="">...</option>
                            {
                                organismes.map((organisme) => 
                                <option key={organisme['id']} value={organisme['id']}>{organisme['nom']}</option>
                                )
                            }
                        </select>
                    </div>


                    <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                        <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                            <Add width="12" />
                            <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Inviter" />
                        </div>
                    </div>
                    
                </div>
            </form>
        </div>
	);
}

export default InvitUser;
