import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import CopyHyp from "./components/copyHyp";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import GrilleSaisie from "./Grilles/Prospective/GrilleSaisie";
import { ReactComponent as Copy } from './Img/icon-copy.svg';


const SaisieProsp = (props) =>
{
    const [modalVisible, setModalVisible] = useState(false);
    const [objet, setObjet] = useState([]);
    const [annees, setAnnees] = useState([]);
    let fctinv = window.location.pathname.split('/')[3];
    let recdep = window.location.pathname.split('/')[4];
    const [hypothese, setHypothese] = useState(null);

    const purple = '#6571FE'

    async function getData_brut(id_budget, liste_an, hyp)
    {
        const formvars = {
            'getGrilleSaisieProsp': true,
            'id_budget': id_budget,
            'fctinv': fctinv,
            'recdep': recdep,
            'hypothese': hyp,
            'an_deb': liste_an[0],
            'an_fin': liste_an[liste_an.length-1]
        }
        await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(formvars))
        .then(async (ret) => {
            console.log(ret.data)
            if (ret.data && ret.data.length != [])
            {
                var tmp = [];
                var i = 0;
                var j = 1;
                await ret.data.forEach(async element => {
                    const infos = [element[0].matricule, element[0].compte, element[0].nom, element[0].indentation, element[0].calcul]
                    tmp[i] = [infos]
                    j = 1
                    element[1].forEach(annee => {
                        tmp[i][j] = annee
                        tmp[i][j].push(false);
                        j++;
                    });
                    i++;
                });
                
                await setObjet(tmp)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    useEffect(async () => {
        if (props.user === null) {
            await props.getUser()
        }
        else
        {
            setHypothese('h'+props.user.hypothese);
            // Recuperation de la grille de saisie
            let prosp_an_deb = ''
            let prosp_an_fin = ''
            let retro_an_fin = ''
            await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
            .then((result) => {
                result.data.forEach(element => {
                    if (element[2] === 'prosp_an_deb') {
                        prosp_an_deb = parseInt(element[3])
                    }
                    if (element[2] === 'prosp_an_fin') {
                        prosp_an_fin = parseInt(element[3])
                    }
                    if (element[2] === 'retro_an_fin') {
                        retro_an_fin = parseInt(element[3])
                    }
                });
            }).catch((err) => {console.log(err)})

            if (prosp_an_deb === '' || prosp_an_fin === '' || retro_an_fin === '')
            {
                await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
                .then(async function (res) {
                    if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                        if (prosp_an_deb === '') {
                            prosp_an_deb = parseInt(res.data.prosp_an_deb)
                        }
                        if (prosp_an_fin === '') {
                            prosp_an_fin = parseInt(res.data.prosp_an_fin)
                        }
                        if (retro_an_fin === '') {
                            retro_an_fin = parseInt(res.data.retro_an_fin)
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
            var liste_an = []
            var i = 1
            liste_an[0] = parseInt(retro_an_fin)
            while (prosp_an_deb <= prosp_an_fin)
            {
                liste_an[i] = parseInt(prosp_an_deb)
                prosp_an_deb++;
                i++;
            }
            await setAnnees(liste_an)
            await getData_brut(props.user.id_budget, liste_an, 'h'+props.user.hypothese)
        }
    }, [props.user])


	return (
        <Access needConnection={true} type_user={[0]}>
            <NotificationContainer />
            <Navbar user={props.user} />
            
            <div style={{display: 'flex'}}>
                <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <p>Prospective</p>
                        <p style={{marginLeft: 5}}>\</p>
                        <p style={{marginLeft: 5}}>{fctinv.toLowerCase() === 'inv'?'Investissement':'Fonctionnement'}</p>
                        <p style={{marginLeft: 5}}>\</p>
                        {recdep.toLowerCase() === 'recette'?
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Recettes</p>
                        :null}
                        {recdep.toLowerCase() === 'depense'?
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Dépenses</p>
                        :null}
                </div>

                <div style={{flex: 1}}>
                    <p style={{flex: 1, margin: 20}}>{props.user?props.user.nom_budget:null}</p>
                </div>

                <div style={{margin: 20, display: 'flex'}}>
                    <div style={{marginLeft: 20}}>
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: purple, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                            onClick={() => {
                                setModalVisible(!modalVisible)
                            }}
                        >
                            <Copy width="12"/>
                            <p style={{marginLeft: 5}}>Copier une hypothèse</p>
                        </button>
                    </div>
                </div>
            </div>

            {props.user && modalVisible?
            <CopyHyp
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                fctinv={fctinv}
                hypothese={hypothese}
                id_budget={props.user.id_budget}
            />
            :null}

            {props.user && objet.length != 0 && annees.length != 0?
            <GrilleSaisie
                user={props.user}
                fctinv={fctinv}
                recdep={recdep}
                hypothese={hypothese}
                id_budget={props.user.id_budget}
                objet={objet}
                annees={annees}
                setObjet={setObjet}
            />
            :null}
        </Access>
	);
}

export default SaisieProsp;
