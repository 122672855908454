import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import BounceLoader from "react-spinners/BounceLoader";

const Logout = () =>
{
    let history = useHistory();
    const purple = '#6571FE'

    useEffect(() => {
        if (localStorage.getItem("session_token") !== "")
        {
            const url = 'https://app-slfinance.fr/api/connexion.php?session_token='+localStorage.getItem("session_token");
            axios.get(url)
            localStorage.removeItem("session_token");
        }

        history.push("/");
    }, [])


    return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}></div>
            <div style={{marginTop: 100, backgroundColor: 'red'}}>
                <BounceLoader color={purple} />
            </div>
            <div style={{flex: 1}}></div>
        </div>
    );
}

export default Logout;
