import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanEncoursDettePreteur(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)
    var index = 0;
    const colors = [
        '#8e44ad',
        '#2980b9',
        '#27ae60',
        '#f1c40f',
        '#e74c3c',
        '#2c3e50'
    ];

    useEffect(async () => {

        var filename = "Répartition de l\'encours de dette par prêteur";
        
        await axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
        	'PreteursEncoursDette': true,
            'id_budget': props.user.id_budget,
            'annees': props.annees
        }))
        .then(async (res) => {
            
            var tmpseries = [];
            res.data.forEach(element => {
                if (!colors[index]) {
                    index = 0;
                }
                tmpseries.push({
                    name: element[0],
                    data: element[1],
                    color: colors[index],
                    type: 'column'
                })
                index++;
            });

            var state = {
                series: tmpseries,
                options: {
                    title: {
                        text: filename
                    },
                    chart: {
                        toolbar: {
                            show: props.download?true:false,
                            export: {
                                svg: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                                png: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                                csv: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                            },
                        },
                        type: 'bar',
                        stacked: true
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4
                        },
                    },
                    legend: {
                        position: 'right',
                        offsetY: 40
                    },
                    xaxis: {
                        categories: props.annees
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return addSpace(Math.round(value*100)/100, true) + " €";
                            }
                        },
                    },
                    dataLabels: {
                        formatter: (val) => {
                            return addSpace(val > 999 || val < 999?Math.round(val/1000):val, true) + (val > 999 || val < 999?" k":'');
                        }
                    },
                    fill: {
                        opacity: 1
                    }
                }
            }

            await setOptions(state.options)
            await setSeries(state.series)
        })
    }, [])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:300}
                type="bar"
            />:null}
		</div>
	)
}

export default BilanEncoursDettePreteur;
